import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient, private analyticsService: GoogleAnalyticsService) { }

  async getCats() {
    return await new Promise((slv) => {
      this.http.post(environment.apiCatsListApiURL, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            //console.log(data.data);
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async getCat(idCat) {
    return await new Promise((slv) => {
      this.http.post(environment.apiOneCatApiURL, { idCat: idCat }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            //console.log(data.data);
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async getProductsByCat(catID, page) {
    return await new Promise((slv) => {
      this.http.post(environment.apiProductsbycatApiURL, {"cat": catID.toString(), "page": page.toString()}).subscribe(
        (data: any) => {
          //console.log("Cmd getProductsByCat")
          //console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
            //console.log(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async searchProduct(keyword) {
    return await new Promise((slv) => {
      this.http.post(environment.apiSearchProduct, {"keyword": keyword})
      .subscribe(
        (data: any) => {
          //console.log("Cmd searchProduct")
          //console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async searchNameProduct(keyword) {
    return await new Promise((slv) => {
      this.http.post(environment.apiSearchNameProduct, {"keyword": keyword})
      .subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async getProduct(idPrd) {
   //console.log("prdidddddddddddddddddddd");
   //console.log(idPrd);
    return await new Promise((slv) => {
      this.http.post(environment.apiProduct + "/" + idPrd, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            //console.log(data.data.prd);
            this.analyticsService.viewItem(data.data.prd._id, data.data.prd.nom_produit, 
              data.data.prd.categorie_produit, data.data.prd.stock_reelle, data.data.prd.prix_produit)

            slv(data.data);
          } else slv(false);
        },
        (error) => {
     //     console.log("prd error")
     //     console.log(error)
          slv(false);
        }
      );
    });
  }

  async getSliders() {
    return await new Promise((slv) => {
      this.http.post(environment.apiSlidersListApiURL, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            //console.log(data.data);
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
}
