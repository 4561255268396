import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductsService } from 'src/app/services/products/products.service';
import { UsersService } from 'src/app/services/users/users.service';
import { CartService } from 'src/app/services/cart/cart.service';
import * as $ from 'jquery';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  
  nbrItem: number;



  slides = [
    { img: "assets/images/carousel-18.png" },
    { img: "assets/images/carousel-16.png" },
    { img: "assets/images/carousel9.png" },
    { img: "assets/images/carousel10.png" },
    { img: "assets/images/carousel12.png" },
    { img: "assets/images/carousel-19.png" },
    { img: "assets/images/carousel13.png" },
    { img: "assets/images/carousel15.png" },
    { img: "assets/images/carousel2.png" },
    { img: "assets/images/carousel14.png" },

  ];
  slideConfig = { "slidesToShow": 1, "slidesToScroll": 1, "autoplay": true };

  productsList: any = [];
  allItems: any = []
  currentPage = 0;

  batch = 20;
  lastKey = '';
  finished = false;
  public msg: String = '';
  catId: string = ""
  cat: any = {}
  catsList: any = [];
  slidersList: any = []
  basePathImage: any = "https://assets.marketbey.tn/produits/"
  basePathSliders: string = environment.baseSlidersURL+"0"

  constructor(private prdServices: ProductsService,
    private authService: UsersService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService, 
    private cartService: CartService,
    private dialog: MatDialog,
    private analyticsService : GoogleAnalyticsService) { }


  async ngOnInit() {
    window.scrollTo(0, 0);

    this.route.queryParams.subscribe(async (params) => {
      this.catId = params.param;
      if (this.catId == null) {
        this.catId = "5efa076fe23419566e733bb3"
      }
      await this.getCat()
      await this.listSliders();
      this.productsList = []
      this.currentPage = 0
      await this.listProducts(this.catId, this.currentPage);
    })

    this.allItems = await this.cartService.getAllItem()
  }

  async getCat() {
    await this.prdServices.getCat(this.catId).then((res: any) => {
      if (res) {
        this.cat = res;
      }
      //console.log("list categories");
      //console.log(res);
    });
  }

  getProductsByCat(category) {
    this.productsList = []
    this.listProducts(category.idString, this.currentPage);
  }
  authentification(username, password) {
    //location.href = '/home';
    this.authService.login({ username: username.value, password: password.value }).then((res: any) => {
    })
  }

  async onScroll() {
    if (this.finished === false) {
      await setTimeout(async () => {                           //<<<---using ()=> syntax
        //this.messageSuccess = false;
        await this.spinner.show();
        this.currentPage++
        await this.listProducts(this.catId, this.currentPage);
      }, 1000);
    } else {
      this.spinner.hide();
      this.finished = true
    }

  }

  async listProducts(catID, currentPage) {
    await this.prdServices.getProductsByCat(catID, currentPage).then((res: any) => {
      // console.log("res pagination produits : ");
      // console.log(res);
      if (res) {
        this.spinner.hide();
        if (res.length === 0) {
          this.finished = true
        } else {
          this.finished = false
          this.productsList = this.productsList.concat(res);
        }
      }
      //console.log(res);
    });
  }

  async listCats() {
    await this.prdServices.getCats().then((res: any) => {
      if (res) {
        this.catsList = res;
      }
      //console.log("list categories");
      //console.log(res);
    });
  }

  async listSliders() {
    await this.prdServices.getSliders().then((res: any) => {
      //console.log("sliderssss")
      //console.log(res)
      if (res) {
        this.slidersList = res;
      }
      //console.log("list categories");
      //console.log(res);
    });
  }

  async addToCart(product) {
    // this.msgError = null;
    // console.log("product ++++++++++++ ")
    // console.log(product)
    var item = {
      id: product.id,
      // category: this.product.prd.category,/////
      // currency: this.product.prd.currency,//////
      description: product.description,
      name: product.name,
      image: product.image.replace('https://assets.marketbey.tn/produits/',''),
      // isVariable: this.product.prd.isVariable,/////
      selectedVariation: {},//////
      quantite: 1,
      price: product.price,
      // pricetxt: this.product.prd.pricetxt,////////
      quantiteLimite: product.quantiteLimite,
      tested: product.tested,
      isDelivery: product.isDelivery
    }

    if (this.cartService.isOneProductFree() && item.price=="0") {
      // this.msgError = "vous avez déjà ajouté un produit gratuit"
    } else if(product.stock_reelle <= 0) {
      // this.msgError = "Quantité épuisée !"
    } else {
      this.cartService.addToCart(item)
      this.dialog.open(ModalComponent);
      this.nbrItem = await this.cartService.getNbrItems()
      this.analyticsService.addToCart(product.id, product.nom_produit, product.category, product.quantite, product.prix_produit)
    }
  }

}
