import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart/cart.service';
import { UsersService } from 'src/app/services/users/users.service';
import { CmdsService } from 'src/app/services/cmds/cmds.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})

export class CheckoutComponent implements OnInit {

  villes = ['Ariana',
    'Béja',
    'Ben Arous',
    'Bizerte',
    'Gabès',
    'Gafsa',
    'Jendouba',
    'Kairouan',
    'Kasserine',
    'Kébili',
    'Kef',
    'Mahdia',
    'Manouba',
    'Médenine',
    'Monastir',
    'Nabeul',
    'Sfax',
    'Sidi Bouzid',
    'Siliana',
    'Sousse',
    'Tataouine',
    'Tozeur',
    'Tunis',
    'Zaghouan'
  ];

  nbrItem: number;
  user: any = {}
  allItems: any = []

  totalPrice: number = 0
  choiceLivraison: any = {}
  // livraisonStandard: any = {}

  priceLivraison: number = 0
  nbrProductIsFree: number = 0
  msgErrorForm: string = null;

  isCheckCoupon: boolean = false
  dataCoupon: any = {}
  codeCoupon: string = ""
  amountCoupon: number = 0

  constructor(private cartService: CartService,
    private userService: UsersService,
    private cmdsService: CmdsService,
    private router: Router) { }

  async ngOnInit() {
    window.scrollTo(0, 0);

    await this.getInfosUser()
    this.nbrItem = await this.cartService.getNbrItems()
    this.allItems = await this.cartService.getAllItem()

    await this.listLivraison()
    await this.totalPriceFromCart()
    await this.verifyPriceLivraison()

    if (this.allItems.length == 0 || this.totalPrice == 0 ||
      (this.cartService.isOneProductFree() && this.totalPrice < 20)) {
      this.router.navigate(['/'])
    }
  }

  async getInfosUser() {
    await this.userService.getUserInfos().then((res: any) => {
      if (res) {
        this.user = res;
      }
    });
  }

  checkPriceLivraison(event: any) {
    //console.log("event.target.value")
    //console.log(event.target.value)
    this.priceLivraison = Number(event.target.value)
    this.priceLivraison = this.priceLivraison - ((this.priceLivraison/this.nbrItem)*this.nbrProductIsFree)
  }

  totalPriceFromCart() {
    let total = 0
    this.allItems.forEach(element => {
      if (element.isDelivery) {
        this.nbrProductIsFree++
      }
      total += element.price * element.quantite
    });
    this.totalPrice = total
    // console.log("nrbProductIsFree")
    // console.log(this.nbrProductIsFree)
  }

  async listLivraison() {
    await this.cmdsService.getPrixLivraison().then((res: any) => {
      if (res) {
        if (res) {
          this.choiceLivraison = res
        }
      }
    });
  }
  verifyPriceLivraison() {
    if (this.user.last_shipping_address.ville == "Ariana" || this.user.last_shipping_address.ville == "Ben Arous"
      || this.user.last_shipping_address.ville == "Manouba" || this.user.last_shipping_address.ville == "Tunis") {
      this.priceLivraison = this.choiceLivraison.livraisonStandard
    } else {
      this.priceLivraison = this.choiceLivraison.livraisonStandardGrandTunis
    }
    this.priceLivraison = this.priceLivraison - ((this.priceLivraison/this.nbrItem)*this.nbrProductIsFree)
  }
  selectChangeVille(event: any) {
    //update the ui
    this.verifyPriceLivraison()
  }

  async addCommande() {
    if (this.user.last_shipping_address.adresse.trim() == "" || this.user.last_shipping_address.ville.trim() == "" || this.user.tel_user.trim() == "") {
      this.msgErrorForm = "Vérifier votre adresse, votre ville et votre numéro de téléphone"
    } else {
      let cmdToSend = [];
      this.allItems.forEach(element => {
        cmdToSend.push({
          "id": element.id,
          "price": element.price,
          "quantite": element.quantite,
          "selectedVariation": element.selectedVariation,
          "is_variable": element.is_variable,
        })
      });
      await this.verifyPriceLivraison()
      await this.cmdsService.addCmd(cmdToSend, this.user._id, "LIVRAISON",
        this.priceLivraison, null, this.user.last_shipping_address.adresse, this.user.last_shipping_address.ville,
        this.user.last_shipping_address.codepostal, this.user.tel_user, this.codeCoupon).then(async (res: any) => {
          if (res) {
            //console.log("add commande");
            //console.log(res)
            await this.cartService.emptyCart()
            await this.router.navigate(['/pass-cmd'])
          }
        });
    }
  }

  async checkCoupon(promo) {
    if (promo.value.trim() != "") {
      await this.cmdsService.checkCoupon(promo.value, this.user._id).then((res: any) => {
        if (res) {
          if (res) {
            this.dataCoupon = res
            this.codeCoupon = promo.value
            this.isCheckCoupon = true
            if (this.dataCoupon.type_reduction == "PERCENT") {
              this.amountCoupon = (this.totalPrice * this.dataCoupon.amount) / 100
            } else if (this.dataCoupon.type_reduction == "AMOUNT") {
              this.amountCoupon = this.dataCoupon.amount
            }
          }
        }
      });

    }
  }

}
