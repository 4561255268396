<footer>
    <div>
        <div class="row space-one">
            <div class="col3 marketbey">
                <div class="img-logo"></div>
                <ul class="lst">
                    <li><a class="link-lst" [routerLink]="[ '/']">Marketbey.tn est une plateforme E-commerce
                            destinée à la femme, sa famille et sa maison.</a></li>

                    <li><a class="link-lst" [routerLink]="[ '/']">- Livraison à domicile sur toute la Tunisie.</a>
                    </li>

                    <li><a class="link-lst" [routerLink]="[ '/']">- Service client 7/7. </a></li>

                    <li><a class="link-lst" [routerLink]="[ '/']">- Paiement à la livraison. </a></li>
                </ul>
            </div>

            <div class="col6 marketbey">
                <span class="apr-text">Contactez-nous</span>
                <ul class="lst">
                    <li><a class="link-lst">(+216) 27 305 100</a></li>
                    <li><a class="link-lst">CHARGUIA, TUNIS-CARTHAGE</a></li>
                    <li><a class="link-lst">Contact@marketbey.tn </a></li>

                </ul>
            </div>

            <div class="col3 marketbey">

                <div class="pl-st">

                    <div class="logo-footer">

                    </div>
                    <div class="txt-st">
                        <span class="apr-text-footer">MARKETBEY DANS VOTRE POCHE!</span>
                        <h5>Téléchargez notre application gratuite</h5>
                    </div>
                </div>

                <div class="bt-pl">
                    <!-- <a href="##############" title="Retrouvez l'application sur l'App Store" class="brd-btn -mrs"
                        target="_blank" rel="nofollow noopener">
                        <i class="fab fa-apple icon-footer"></i><span class="gle-pl">App store</span>
                    </a> -->
                    <a href="https://play.google.com/store/apps/details?id=mobile.marketbey.com.marketbey"
                        title="Retrouvez l'application sur Google Play" class="brd-btn" target="_blank"
                        rel="nofollow noopener"><i class="fab fa-google-play icon-footer"> </i><span
                            class="gle-pl">Google Play</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="row space-one">
        <div class="col3 apropos">
            <span class="apr-text">A PROPOS</span>
            <ul class="lst">
                <li><a class="link-lst" [routerLink]="[ '/apropos-nous']">Qui sommes-nous</a></li>
            </ul>
        </div>
        <div class="col3 apropos">
            <span class="apr-text">SERVICE CLIENT</span>
            <ul class="lst">
                <li><a class="link-lst" [routerLink]="[ '/service']" title="service">Aide & FAQ</a></li>
                <li><a class="link-lst" [routerLink]="[ '/contact']" title="Contactez-nous">Contactez-nous</a></li>
                <li><a class="link-lst" [routerLink]="[ '/paiement']" title="paiement">Paiement à la livraison</a></li>
                <li><a class="link-lst" [routerLink]="[ '/retour']" title="retour">Politique de retour</a></li>
                <li><a class="link-lst" [routerLink]="[ '/politique-de-confidentialite']" title="retour">Politique de Confidentialité</a></li>

            </ul>
        </div>

        <div class="col3 apropos">
            <span class="apr-text">CONTACT</span>
            <ul class="lst">
                <li><a class="link-lst" [routerLink]="[ '/profil']" title="profil" *ngIf="isConnected==true">Compte
                        client</a></li>
                <li><a class="link-lst" href="#">Compte vendeur</a></li>
                <li><a class="link-lst" [routerLink]="[ '/login']" title="login" *ngIf="isConnected==false"><i
                            class="fab fa-facebook-f"></i> Se connecter via Facebook</a></li>
            </ul>
        </div>

        <div class="col3 apropos">
            <span class="apr-text">RETROUVEZ NOUS SUR !</span>
            <div class="social-media-footer">
                <a href="https://www.facebook.com/Marketbey.tn" title="Facebook MarketBey" target="_blank"
                    rel="noopenner"><i class="fab fa-facebook-f"></i></a>
                <a href="https://www.instagram.com/marketbey/" title="Instagram MarketBey" target="_blank"
                    rel="noopenner"><i class="fab fa-instagram"></i></a>
                <a href="https://www.youtube.com/channel/UCw9pmPxIOLxJAko5rFWmfFQ" title="YouTube MarketBey"
                    target="_blank" rel="noopenner"><i class="fab fa-youtube"></i></a>
            </div>
        </div>
    </div>
    <div class="copyright">
        <div class="container-fluid">
            <span>© 2021 - <a href="#" title="">Market Bey.</a> Tous droits réservés.</span>

        </div>
    </div>
</footer>