import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart/cart.service';
import { UsersService } from 'src/app/services/users/users.service';
import { CmdsService } from 'src/app/services/cmds/cmds.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModelProfilComponent } from 'src/app/components/model-profil/model-profil.component';
// import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {

  villes = ['Ariana',
    'Béja',
    'Ben Arous',
    'Bizerte',
    'Gabès',
    'Gafsa',
    'Jendouba',
    'Kairouan',
    'Kasserine',
    'Kébili',
    'Kef',
    'Mahdia',
    'Manouba',
    'Médenine',
    'Monastir',
    'Nabeul',
    'Sfax',
    'Sidi Bouzid',
    'Siliana',
    'Sousse',
    'Tataouine',
    'Tozeur',
    'Tunis',
    'Zaghouan'
  ];


  nbrItem: number;
  user: any = {
    last_shipping_address: {}
  }
  allItems: any = []

  totalPrice: number = 0
  choiceLivraison: any = {}
  priceLivraison: number = 0

  constructor(private cartService: CartService,
    private userService: UsersService,
    private cmdsService: CmdsService,
    private router: Router,
    private dialog: MatDialog) { }

  async ngOnInit() {
    window.scrollTo(0, 0);

    await this.getInfosUser()
    // this.nbrItem = await this.cartService.getNbrItems()
    // this.allItems = await this.cartService.getAllItem()
    // await this.totalPriceFromCart()
    // await this.listLivraison()
  }

  async getInfosUser() {
    await this.userService.getUserInfos().then((res: any) => {
      //console.log("res getInfos")
      //console.log(res)
      if (res) {
        this.user = res;
        //console.log("idddddddddddddddddddddd")
        //console.log(this.user)
        //console.log(this.user["_id"])
        //this.analyticsService.setUserProperties(this.user["_id"], this.user["nom_user"] + "" + this.user["prenom_user"], this.user["email_user"])
      }
    });
  }

  // checkPriceLivraison(event: any) {
  //   console.log("event.target.value")
  //   console.log(event.target.value)
  //   this.priceLivraison = Number(event.target.value)
  // }

  // totalPriceFromCart() {
  //   let total = 0
  //   this.allItems.forEach(element => {
  //     total += element.price * element.quantite
  //   });
  //   this.totalPrice = total
  // }

  // async listLivraison() {
  //   await this.cmdsService.getPrixLivraison().then((res: any) => {
  //     if (res) {
  //       console.log("listLivraison")
  //       console.log(res)
  //       if (res) {
  //         this.choiceLivraison = res
  //         this.priceLivraison = this.choiceLivraison.livraisonStandard
  //       }
  //     }
  //   });
  // }

  // async addCommande() {
  //   console.log("user.nom")
  //   console.log(this.user.nom)
  //   let cmdToSend = [];
  //   this.allItems.forEach(element => {
  //     cmdToSend.push({
  //       "id": element.id,
  //       "price": element.price,
  //       "quantite": element.quantite,
  //       "selectedVariation": element.selectedVariation,
  //       "is_variable": element.is_variable,
  //     })
  //   });

  //   await this.cmdsService.addCmd(cmdToSend, this.user.id, "LIVRAISON",
  //         this.priceLivraison, null,
  //         this.user.adresse, this.user.ville, this.user.codepostal, this.user.numtel).then(async (res: any) => {
  //     if (res) {
  //       await this.cartService.emptyCart()
  //       await this.router.navigate(['/pass-cmd'])
  //       console.log("listLivraison")
  //       console.log(res)
  //     }
  //   });
  // }

  async updateUser() {
    // console.log("this.user update ")
    // console.log(this.user)
    await this.userService.updateInfosUser(this.user).then((res: any) => {
      if (res) {
        //console.log("true")
        this.dialog.open(ModelProfilComponent);
        //console.log(res)
      }
    });
  }

}
