<!-- <app-navbar></app-navbar>
<div class="page-content">
    <app-menu></app-menu>
    <main> -->
        <section class="checkout-page">
            <div class="container-fluid">
                <div class="heading-sub">
                    <ul class="other-link-sub">
                        <li><a [routerLink]="[ '/']" title="Acceuil">Acceuil</a></li>
                        <li>Service client</li>
                    </ul>
                </div>
                <!-- <ul class="breadcrumb">
                    <li>
                        <a href="checkout-1.html">01. Panier</a>
                    </li>
                    <li>
                        <a href="checkout-2.html">02. Livraison</a>
                    </li>
                    <li>
                        <a class="active" href="checkout-3.html" title="">03. Commande</a>
                    </li>
                </ul> -->
                <div class="complete-page text-center">
                    <h2 class="status">
                        <a class="logo" [routerLink]="[ '/']" title="MarketBey"><img src="assets/images/logo_blue.png" alt="images"
                            height="80px" class="img-reponsive"></a>
                        
                        <!-- <span class="iconify" data-icon="ion-checkmark-circled" data-inline="false"></span> -->
                        <br /><br />
                        Service clientèle Market Bey
                    </h2>
                </div>
                <div class="service-client" [innerHTML]="svCl"></div>

                <!-- <div>
                    <p> Toutes nos félicitations! Votre commande a été créée avec succès. Vous recevrez un e-mail de confirmation ou un SMS. 
                        Notre service client pourrait vous contacter sous peu pour vérifier votre commande.</p>
                        <br />
                </div>
                <div>
                    <h3>SUIVRE VOTRE COMMANDE</h3>
                    <p>
                        Vous pouvez suivre votre commande dans: <br />
                        <a>Historique de commandes</a> 
                        <a> Détail</a>
                    </p>
                </div> -->
            </div>
        </section>
    <!-- </main>
</div>
<app-footer></app-footer> -->
<!-- <footer>
    <div class="copyright">
        <div class="container-fluid">
            <span>© <a href="#" title="">MarketBey</a> - All Rights Reserved.</span>

        </div>
    </div>
</footer> -->
