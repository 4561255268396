<!-- <app-navbar nbrItem="{{ nbrItem }}"></app-navbar>
<div class="page-content">
    <app-menu></app-menu>
    <main> -->
        <section class="profil">
            <div class="container-fluid">
                <div class="heading-sub">
                    <ul class="other-link-sub">
                        <li><a [routerLink]="[ '/']" title="Acceuil">Acceuil</a></li>
                        <li><a [routerLink]="[ '/profil']" title="Panier">Profil</a></li>
                    </ul>
                </div>
                <!-- <ul class="breadcrumb">
                    <li>
                        <a [routerLink]="[ '/cart']" title="Panier">01. Panier</a>
                    </li>
                    <li>
                        <a href="checkout-2.html" class="active">02. Livraison</a>
                    </li>
                    <li>
                        <a href="checkout-3.html" title="">03. Commande</a>
                    </li>
                </ul> -->
                <div class="orders-profil">
                    <div class="row">
                        <div class="col-xs-12 col-md-5">
                            <div class="aside-order">
                                <h2>Mon Profil</h2>
                                <div class="NavPanel__Wrapper-uc24jh-0 fajsNi">
                                    <!-- <img alt="Hajer Romdhani"
                                        src="https://graph.facebook.com/10216075946844644/picture?width=100&amp;height=100"
                                        class="ProfilePicture__ProfileImage-sc-19kphna-0 gxljgF"> -->
                                    <ngx-avatar class="circle-avatar" name="{{user.prenom_user}} {{user.nom_user}}"
                                        size=100 bgColor="#35b7ec" fgColor="white">
                                    </ngx-avatar>

                                    <div class="NavPanel__Name-uc24jh-1 jvvBeL">{{user.prenom_user}} {{user.nom_user}}
                                    </div>
                                    <!-- <div class="NavPanel__Country-uc24jh-2 kfnRmo">TN</div> -->
                                    <div class="NavPanel__Divider-uc24jh-5 fLtrQY"></div>
                                    <div class="NavPanel__ProfileStatContainer-uc24jh-6 gLjoQi">
                                        <div class="NavPanel__ProfileStat-uc24jh-7 inbddE">
                                            <div class="NavPanel__ProfileStatNumber-uc24jh-8 ZmYQj">0</div>Abonnés
                                        </div>
                                        <div class="NavPanel__ProfileStat-uc24jh-7 inbddE">
                                            <div class="NavPanel__ProfileStatNumber-uc24jh-8 ZmYQj">0</div>Abonnements
                                        </div>
                                    </div>
                                </div>

                                <!-- <table class="table table-product">
                                    <thead>
                                        <tr>
                                            <th>
                                                <a class="profile" [routerLink]="[ '/profil']" title="profil">
                                                    <div class="item-user">
                                                        <ngx-avatar class="circle" name="{{user.prenom}} {{user.nom}}"
                                                            size=40 bgColor="#35b7ec" fgColor="white">
                                                        </ngx-avatar>
                                                    </div>
                                                </a>
                                            </th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="product-name"><div class="profil-user">
                                                <div class="profil-name">{{user.prenom}} {{user.nom}}</div>
                                                
                                            </div>
                                        </td>
                                           
                                        </tr>
                                    </tbody>
                                </table> -->
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-7">
                            <!-- <div class="login-required">
                                <p>Retour Client? Cliquer <a href="#" title="" class="login">ICI</a> pour connecter
                                </p>
                                <p>Avez-vous un coupon? Cliquer <a href="#" title="" class="coupon-code">ICI</a> Pour entrer
                                    ton code
                                </p>
                            </div> -->
                            <div class="billing-details">
                                <div class="billing-details-heading">
                                    <h2 class="billing-title">
                                        Vérifiez vos informations
                                    </h2>
                                </div>
                                <div class="billing-details-content">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12">
                                                <strong>Nom *</strong>
                                                <input type="text" name="prenom" class="form-control"
                                                    [(ngModel)]="user.prenom_user">
                                            </div>
                                            <div class="col-sm-6 col-xs-12">
                                                <strong>Prénom *</strong>
                                                <input type="text" name="name" class="form-control"
                                                    [(ngModel)]="user.nom_user">

                                                <!-- <input matInput placeholder="Vehicle Number" class="form-control" name="user.nom" 
                                                    [(ngModel)]="user.nom"> -->

                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <strong>Company Name</strong>
                                                <input type="text" name="company_name" class="form-control"
                                                    value="">
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12">
                                                <strong>Addresse Email *</strong>
                                                <input type="text" name="email" class="form-control"
                                                    [(ngModel)]="user.email_user">
                                            </div>
                                            <div class="col-sm-6 col-xs-12">
                                                <strong>Téléphone *</strong>
                                                <input type="text" name="numtel" class="form-control"
                                                    [(ngModel)]="user.tel_user">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <strong>Country *</strong>
                                                <select name="CountryType" id="country" class="form-control">
                                                    <option value="1">Viet Nam</option>
                                                    <option value="2">USA</option>
                                                    <option value="3">Germany</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <strong>Adresse *</strong>
                                                <input type="text" name="adresse" class="form-control"
                                                    [(ngModel)]="user.last_shipping_address.adresse">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6 col-xs-12">
                                                <strong>Code postal</strong>
                                                <input type="text" name="codepostal" class="form-control"
                                                    [(ngModel)]="user.last_shipping_address.codepostal">
                                            </div>
                                            <div class="col-md-6 col-xs-12">
                                                <strong>Ville *</strong>
                                                <!-- <input type="text" name="ville" class="form-control"
                                                    [(ngModel)]="user.last_shipping_address.ville"> -->

                                                <select name="CountryType" id="country" class="form-control selectric"
                                                    [(ngModel)]="user.last_shipping_address.ville">
                                                    <option *ngFor="let ville of villes" [ngValue]="ville"
                                                        ngDefaultControl>{{ville}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <input type="checkbox" name="acc"> Create an account?
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <strong>Order Note</strong>
                                                <textarea name="note" id="address" tabindex="2"
                                                    class="form-control form-textarea"></textarea>
                                            </div>
                                        </div>
                                    </div> -->
                                    <button type="button" class="btn-order" (click)="updateUser()">Enregistrer</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    <!-- </main>
</div>
<app-footer></app-footer> -->
<!-- <footer>
    <div class="copyright">
        <div class="container-fluid">
            <span>© <a href="#" title="">MarketBey</a> - All Rights Reserved.</span>

        </div>
    </div>
</footer> -->