import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objNgFor', pure: false
})
export class ObjNgForPipe implements PipeTransform {

  // transform(value: any, args: any[] = null): any {
  //   console.log("value")
  //   console.log(value)

  //   console.log("Object.keys(value)")
  //   console.log(Object.keys(value))
    
  //   return Object.keys(value).map(key => Object.assign({ key }, value[key]));
  // }

  transform(obj: Object, args: any[] = null): any {
    let array = [];
    Object.keys(obj).forEach(key => {
        array.push({
            value: obj[key],
            key: key
        });
    });
    return array;
}

}