<!-- <app-navbar></app-navbar>
<div class="page-content">
    <app-menu></app-menu>
    <main> -->
        <section class="checkout-page">
            <div class="container-fluid">
                <div class="heading-sub">
                    <ul class="other-link-sub">
                        <li><a [routerLink]="[ '/']" title="Acceuil">Acceuil</a></li>
                        <li>Paiement</li>
                    </ul>
                </div>
                <!-- <ul class="breadcrumb">
                    <li>
                        <a href="checkout-1.html">01. Panier</a>
                    </li>
                    <li>
                        <a href="checkout-2.html">02. Livraison</a>
                    </li>
                    <li>
                        <a class="active" href="checkout-3.html" title="">03. Commande</a>
                    </li>
                </ul> -->
                <div class="complete-page text-center">
                    <h2 class="status">
                        <a class="logo" [routerLink]="[ '/']" title="MarketBay"><img
                                src="assets/images/logo_blue.png" alt="images" height="80px" class="img-reponsive"></a>

                        <!-- <span class="iconify" data-icon="ion-checkmark-circled" data-inline="false"></span> -->
                        <br /><br />
                        Comment payer à la livraison ?
                    </h2>
                </div>


                <div class="desc-mb">
                    <p> Lors de la finalisation de votre commande, vous pouvez visualiser le montant total de votre
                        commande, vous recevrez par la suite un e-mail de confirmation indiquant les produits et le
                        montant final.
                        Le livreur vous contactera avant son arrivée, le paiement se fait en espèces à la livraison.
                        <br />

                    </p>
                </div>

                <div>
                    <ul>
                        <li>- Assurez-vous de préparer le montant exact de la commande.</li>
                        <li>- Nous acceptons uniquement le paiement en Dinar Tunisien.</li>
                    </ul>
                    <br />
                </div>

                <div>
                    <span><b>La livraison</b></span>
                    <p>
                        Contient deux volets : Frais de livraison et Délais d'expédition
                    </p>
                    <span><b>Délais d'expédition:</b></span>
                    <p>
                        Le délai de livraison est comptabilisé à partir du jour où vous passez votre commande jusqu'au
                        jour de notre première tentative de livraison.
                        Les Jours ouvrés comptent du lundi au samedi. <br />
                        La livraison se fait sous 24h à 48h maximum pour le grand Tunis et de 24h à 72h pour les régions
                        hors Tunis.

                    </p>
                    <span><b>Frais de livraison:</b></span>
                    <p>
                        - Les frais de livraison varient selon la distance entre la ville d'expédition et la ville de la
                        livraison.<br />
                        - La livraison sur le grand Tunis est de 5dt, pour les régions hors Tunis la livraison est de
                        7dt.
                    </p>



                </div>
            </div>
        </section>
    <!-- </main>
</div>
<app-footer></app-footer> -->
<!-- <footer>
    <div class="copyright">
        <div class="container-fluid">
            <span>© <a href="#" title="">MarketBey</a> - All Rights Reserved.</span>

        </div>
    </div>
</footer> -->