// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
var baseURLAPI = 'https://api.marketbey.tn:2043/';

export const environment = {
  production: false,
  
  apiUsersAuth: baseURLAPI + "users/auth",
  apiUsersRegister: baseURLAPI + "users/inscrip",
  apiUsersSocialAuth: baseURLAPI + "users/socialauth",
  apiUpdateUser: baseURLAPI + "users/maj",
  apiInfosUser: baseURLAPI + "users/infos",

  apiCatsListApiURL: baseURLAPI + "products/cats",
  apiSlidersListApiURL: baseURLAPI + "products/sliders",
  apiOneCatApiURL: baseURLAPI + "products/one-cat",
  apiProductsbycatApiURL: baseURLAPI + "products/bycat",
  apiProduct: baseURLAPI + "products/product",
  apiSearchProduct: baseURLAPI + "products/search",
  apiSearchNameProduct: baseURLAPI + "products/prdts-search",
  
  apiPrixlivraison: baseURLAPI + "cmds/prixlivraison",
  apiAddcmd: baseURLAPI + "cmds/add",
  apiForgetPw: baseURLAPI + "users/mdpoublie",
  apiServiceCl: baseURLAPI + "cgv",
  apiCmdsUser: baseURLAPI + "cmds/usercmd",
  apiOneCmdByID: baseURLAPI + 'tracking/cmdbyid',

  apiCheckcoupon: baseURLAPI + "cmds/coupon",

  baseImagesURL: 'https://assets.marketbey.tn/produits/',
  baseVideoURL: 'https://assets.marketbey.tn/videos/',
  baseSlidersURL: 'https://adm.marketbey.tn/admin/',

  firebaseConfig  : {
    apiKey: "AIzaSyCD32IFxnEfzr0ZSIEUDvsPra97PvUtHto",
    authDomain: "marketbey-c9ab9.firebaseapp.com",
    databaseURL: "https://marketbey-c9ab9.firebaseio.com",
    projectId: "marketbey-c9ab9",
    storageBucket: "marketbey-c9ab9.appspot.com",
    messagingSenderId: "217516541362",
    appId: "1:217516541362:web:a6e8ad15e025b1e7b810ab",
    measurementId: "G-HTE4GJP322"
  }
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
