import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  allItems: any = []

  constructor() {
    //console.log("constructor")
    this.initCart()
  }

  getNbrItems() {
    return this.allItems?.length
  }

  getAllItem() {
    return this.allItems
  }

  initCart() {

    let cart = localStorage.getItem('cart');
    if (cart == null) {
      localStorage.setItem('cart', JSON.stringify(this.allItems));
    } else {
      let items = JSON.parse(cart);
      if (items != null && items.length > 0) {
        this.allItems = [];

        items.forEach(c => {
          this.allItems.push({
            id: c._id,
            // category: c.category,/////
            // currency: c.currency,//////
            description: c.description_produit,
            name: c.nom_produit,
            image: c.photo_produit,
            // isVariable: c.isVariable,/////
            // selectedVariation: c.selectedVariation,//////
            quantite: 1,
            price: c.prix_produit,
            // pricetxt: c.pricetxt,////////
            quantiteLimite: c.quantiteLimite,
            tested: c.tested
          })
        });
      }
    }
    this.allItems = JSON.parse(cart)
    return this.allItems
  }

  isOneProductFree() {
    if (this.allItems)
      for (let i = 0; i < this.allItems.length; i++) {
        if (this.allItems[i].price == "0") {
          return true
        }
      }
    return false
  }

  saveCart() {
    localStorage.setItem('cart', JSON.stringify(this.allItems));
  }

  addToCart(item) {
    if (this.allItems == null) {
      this.initCart()
    }

    //console.log("this.allItems")
    //console.log(this.allItems)

    let found: Boolean = false;

    if (this.allItems.length > 0) {
      this.allItems.forEach(element => {
        if (element.id == item.id) {
          found = true
          element.quantite++
        }
      });
    }

    if (!found) {
      this.allItems.push(item)
    }

    this.saveCart()
  }

  minuxFromCart(item) {
    if (this.allItems.length > 0) {
      this.allItems.forEach((element, index) => {
        if (element.id == item.id) {
          element.quantite--
          if (element.quantite <= 0) {
            this.allItems.splice(index, 1)
            return;
          }
          // break;
        }

      })
    }

    this.saveCart()
  }

  removeFromCart(item) {
    if (this.allItems.length > 0) {
      this.allItems.forEach((element, index) => {
        if (element.id == item.id) {
          this.allItems.splice(index, 1)
          return;
        }
      })
    }

    this.saveCart()
  }

  emptyCart() {
    this.allItems = [];
    this.saveCart();
  }

}
