import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-forget-pass-word',
  templateUrl: './forget-pass-word.component.html',
  styleUrls: ['./forget-pass-word.component.css']
})
export class ForgetPassWordComponent implements OnInit {

  constructor(private authService: UsersService, 
    private router: Router) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  forgetPassWord(email){
    if(email.value == ""){
      alert("Veuillez remplir le champs.")
    } else {

      this.authService.forgetPw({email:email.value}).then((res: any) => {
        if(res){
          return true
        } else return false
      })
    }
  }

}
