import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart/cart.service';

@Component({
  selector: 'app-pass-cmd',
  templateUrl: './pass-cmd.component.html',
  styleUrls: ['./pass-cmd.component.css']
})
export class PassCmdComponent implements OnInit {

  nbrItem: number;

  constructor(private cartService: CartService) { }

  async ngOnInit() {
    window.scrollTo(0, 0);

    this.nbrItem = await this.cartService.getNbrItems()
  }

}
