<div class="main-top-bar search">
    <a href="/" title="home" class="arrow-back">
        <i class="fa fa-arrow-left"></i>
    </a>
    <div class="col">
        <!-- <form class="search-form">
            <input type="text" name="s" class="form-control" placeholder="Rechercher des produits... " #keyword ngModel>
            <button type="button" class="search-icon" (click)="searchProd(keyword)"></button>
        </form> -->
        <div class="search eOPDGP">
            <form action="" class="search-form" method="post" onsubmit="return false;"
                (onsubmit)="searchProd(searchText)">
                <input class="form-control" type="text" name="search" id="searchText" [(ngModel)]="searchText"
                    autocomplete="off" placeholder="Vous cherchez quoi ?" (ngModelChange)="filterNames($event)">
                <div>
                    <button type="submit" class="search-icon" (click)="searchProd(searchText)"></button>
                </div>
            </form>

            <div tabindex="0" class="cQCxPr hidden-xs hidden-sm" (click)="searchProd(searchText)">Rechercher</div>

            <table class="table table-striped fEIYcM">
                <tbody>
                    <tr *ngFor="let f of filterdName" (click)="searchProd(f)">
                        <td>{{f}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

    <div class="right">
        <div class="menu-hover hidden-xs hidden-sm">
            <a [routerLink]="[ '/login']" title="login" *ngIf="isConnected==true">
                <ngx-avatar class="avatar" name="{{user?.prenom_user}} {{user?.nom_user}}" size=35 bgColor="#35b7ec"
                    fgColor="white">
                </ngx-avatar>
            </a>
            <div class="menu-level">
                <a class="profile" [routerLink]="[ '/profil']" title="profil">
                    <!-- <img alt="Hajer Romdhani" src="https://graph.facebook.com/10216075946844644/picture?width=100&amp;height=100"
                        class="ProfilePicture__ProfileImage-sc-19kphna-0 tHEql"> -->
                    <div class="item-user">
                        <ngx-avatar class="circle" name="{{user?.prenom_user}} {{user?.nom_user}}" size=40
                            bgColor="#35b7ec" fgColor="white">
                        </ngx-avatar>
                    </div>
                    <div class="profil-user">
                        <div class="profil-name">{{user?.prenom_user}} {{user?.nom_user}}</div>
                        <div class="profil-link">Afficher votre profil</div>
                    </div>
                    <div class="profil-svg">
                        <svg width="10" height="15" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"
                            style="transform: rotate(0deg); transition: all 0.25s ease 0s;">
                            <path d="M1 1l4 4.01L1 9" stroke="#192a32" stroke-width="1.5" fill="none"
                                fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">

                            </path>
                        </svg>
                    </div>
                </a>
                <a href="./" title="Home"><i class="fa fa-home"></i>Parcourir</a>
                <a [routerLink]="[ '/search']" title="Rechercher"><i class="fa fa-search"></i>Rechercher</a>
                <a [routerLink]="[ '/cart']" title="cart"><i class="fa fa-shopping-cart"></i>Panier</a>
                <a [routerLink]="[ '/']" title="commandes" *ngIf="isConnected==true"><i
                        class="fas fa-list-ul"></i>Historique de commandes</a>
                <a [routerLink]="[ '/service']" title="service"><i class="fa fa-comment-o"></i>Service client</a>
                <a [routerLink]="[ '/profil']" title="profil" *ngIf="isConnected==true"><i
                        class="fal fa-cog"></i>Paramètres</a>
                <a [routerLink]="[ '/login']" title="login" *ngIf="isConnected==false"><i class="fa fa-sign-in"></i>Se
                    connecter</a>
                <a title="login" *ngIf="isConnected==true" (click)="deconnexion()"><i
                        class="fa fa-sign-out"></i>Déconnexion</a>
            </div>
        </div>

        <a [routerLink]="[ '/login']" class="btn-login hidden-xs hidden-sm" title="login" *ngIf="isConnected==false">
            <div> <i class="fal fa-user-alt"></i>
                Se connecter
            </div>
        </a>

        <a [routerLink]="[ '/cart']" title="cart">
            <div class="item cart">
                <img src="assets/images/cart.png" alt="images" class="img-reponsive">
                <span *ngIf="nbrItem>0">{{nbrItem}}</span>
            </div>
        </a>
        <a title="notification" class="hidden-xs hidden-sm">
            <div class="item user">
                <img src="assets/images/notification1.png" alt="images" class="img-reponsive">
            </div>
        </a>

    </div>
</div>
<div class="page-content search">
    <app-menu></app-menu>
    <section class="bigdeal-search">
        <div class="container-fluid">

            <div class="clearfix"></div>
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6" *ngFor="let prd of productsList">
                    <div class="product-item">
                        <a [routerLink]="['/details-produit']" [queryParams]="{ param: prd.id }">
                            <div class="prod-item-img bd-style-2"
                                style="background-image: url({{prd.image}}); background-position: center; background-repeat: no-repeat; background-size: cover; ">
                                <!-- <img src="{{prd.image}}" alt="images" class="img-responsive"> -->
                                <!-- <div class="button">
                                <a href="#" class="addcart">ADD TO CART</a>
                            </div> -->
                                <div class="product-promo red" *ngIf="prd.ancienprix_produit != ''">
                                    {{(100 - ((prd.price*100)/prd.ancienprix_produit)) | number:'1.0-0'}} %
                                </div>
                                <div class="product-tag black-d" *ngIf="prd.quantiteLimite">Quantite limitée !
                                </div>
                            </div>
                        </a>
                        <div class="product-item-info-related">
                            <span><a [routerLink]="['/details-produit']" [queryParams]="{ param: prd.id }">
                                    {{prd.name}} </a>
                            </span>
                            <div class="prod-price">
                                <span class="price">{{prd.price}} TND</span>
                                <span class="price old" *ngIf="prd.ancienprix_produit!=''">{{prd.ancienprix_produit}}
                                    TND</span>

                                <img class="check" src="assets/images/check.svg" alt="check" width="20px"
                                    *ngIf="prd.tested" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="show-cart">
                    <a size="3" color="1" stretch="0" [routerLink]="[ '/cart']" title="panier" rel="noopener noreferrer"
                        bold="1"
                        class="Button-sc-1r40802-0-Component kKbPQl Button___StyledStyledWishLinkButtonWithKeyboard-sc-1aufq6l-1 ekSPy"
                        tabindex="0" style="margin: 10px; min-width: 104px;">Voir le panier</a>
                    <div class="CartSection__ProductContainer-ld6lqd-2 cKwMyr">
                        <ul class="CartSection__ProductList-ld6lqd-3 cwWmOv">
                            <li class="CartSection__Product-ld6lqd-4 jnYbBK" *ngFor="let product of allItems">
                                <div class="CartSection__ProductImageWrapper-ld6lqd-5 fATNoD">
                                    <img alt="Bracelet de cheville et chaîne de cheville en or rose"
                                        src="{{basePathImage + product?.image}}"
                                        class="CartSection__ProductImage-ld6lqd-6 ekTJhz" style="object-fit: cover;">
                                </div>
                                <div class="CartSection__ProductName-ld6lqd-8 fSkyel">{{product?.name}}</div>
                            </li>
                        </ul>
                    </div>
                    <a size="3" color="1" stretch="0" [routerLink]="[ '/checkout']" title="panier" rel="noopener noreferrer"
                        bold="1"
                        class="Button-sc-1r40802-0-Component kKbPQl Button___StyledStyledWishLinkButtonWithKeyboard-sc-1aufq6l-1 ekSPy"
                        tabindex="0" style="margin: 10px; min-width: 104px;">Commander</a>
                </div>
            </div>

        </div>
    </section>
</div>

