<!-- <div (activate)="onActivate($event)"> -->
<div class="changeable-menu sm" id="sm-menu">
    <a class="profile" [routerLink]="[ '/profil']" title="profil">
        <!-- <img alt="Hajer Romdhani" src="https://graph.facebook.com/10216075946844644/picture?width=100&amp;height=100"
            class="ProfilePicture__ProfileImage-sc-19kphna-0 tHEql"> -->
        <div class="item-user">
            <ngx-avatar class="circle" *ngIf="isConnected==true" name="{{user?.prenom_user}} {{user?.nom_user}}" size=40
                bgColor="#35b7ec" fgColor="white">
            </ngx-avatar>
            <ngx-avatar class="circle" src="assets/images/avatar.png" *ngIf="isConnected==false" size=50
                bgColor="#35b7ec" fgColor="white">
            </ngx-avatar>
        </div>
        <div class="profil-user">
            <div class="profil-name" *ngIf="isConnected==true">{{user?.prenom_user}} {{user?.nom_user}}</div>
            <div class="profil-name" *ngIf="isConnected==false">Se connecter</div>
            <div class="profil-link" *ngIf="isConnected==true">Afficher votre profil</div>
        </div>
        <div class="profil-svg" *ngIf="isConnected==true">
            <svg width="10" height="15" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"
                style="transform: rotate(0deg); transition: all 0.25s ease 0s;">
                <path d="M1 1l4 4.01L1 9" stroke="#192a32" stroke-width="1.5" fill="none" fill-rule="evenodd"
                    stroke-linecap="round" stroke-linejoin="round">

                </path>
            </svg>
        </div>
    </a>
    <a href="./" title="Home"><i class="fa fa-home"></i>Parcourir</a>
    <a [routerLink]="[ '/search']" title="Rechercher"><i class="fa fa-search"></i>Rechercher</a>
    <a [routerLink]="[ '/cart']" title="cart"><i class="fa fa-shopping-cart"></i>Panier</a>
    <a [routerLink]="[ '/commande']" title="commandes" *ngIf="isConnected==true"><i
            class="fas fa-list-ul"></i>Historique de
        commandes</a>
    <a [routerLink]="[ '/service']" title="service"><i class="fa fa-comment-o"></i>Service client</a>
    <a [routerLink]="[ '/profil']" title="profil" *ngIf="isConnected==true"><i class="fal fa-cog"></i>Paramètres</a>
    <a [routerLink]="[ '/login']" title="login" *ngIf="isConnected==false"><i class="fa fa-sign-in"></i>Se connecter</a>

    <a title="deconnexion" *ngIf="isConnected==true" (click)="deconnexion()"><i
            class="fa fa-sign-out"></i>Déconnexion</a>
    <div class="hidden-lg hidden-md">
        <label class="media">Retrouvez-nous sur</label>
        <div class="social-media hidden-lg hidden-md">
            <a href="https://www.facebook.com/Marketbey.tn" title="Facebook MarketBey" target="_blank"
                rel="noopenner"><i class="fab fa-facebook-f"></i></a>
            <a href="https://www.instagram.com/marketbey/" title="Instagram MarketBey" target="_blank"
                rel="noopenner"><i class="fab fa-instagram"></i></a>
            <a href="https://www.youtube.com/channel/UCw9pmPxIOLxJAko5rFWmfFQ" title="YouTube MarketBey" target="_blank"
                rel="noopenner"><i class="fab fa-youtube"></i></a>
        </div>
    </div>
</div>

<div class="changeable-menu">
    <a class="div-cat" *ngFor="let cat of catsList" [routerLink]="['/']" [queryParams]="{ param: cat.idString }"
        (click)="onChangeCat()">
        <img alt="{{cat.label}}" src="{{basePathIcon + cat?.photo}}" class="icon-cat hidden-xs hidden-sm"
            style="object-fit: contain; ">
        <div class="name-cat" [style.color]="cat.color">{{cat.label}}</div>
    </a>


    <!-- <div *ngFor="let cat of catsList" class="changeable-sous-menu" [routerLink]="['/home']"
        [queryParams]="{ cat: cat.idString }" (click)="onChangeCat()">
        {{cat.label}}
    </div> -->


    <!-- <a href="./shop-list.html" title="Accessoires, Mentres Et Bijoux ">
            Accessoires, Mentres Et Bijoux
        </a>
        <a href="./shop-list.html" title="Maison El Deco ">Maison El Deco </a>
        <a href="./shop-list.html" title="Telephonie ">Telephonie </a>
        <a href="./shop-list.html" title="Luneffes De Soleil ">Luneffes De Soleil </a>
        <a href="./shop-list.html" title="Artsanat ">Artsanat </a>
        <a href="./shop-list.html" title="Recepteur Et Cameras ">Recepteur Et Cameras </a> -->

    <div class="hidden-xs hidden-sm">
        <label class="media">Retrouvez-nous sur</label>
        <div class="social-media hidden-xs hidden-sm">
            <a href="https://www.facebook.com/Marketbey.tn" title="Facebook MarketBey" target="_blank"
                rel="noopenner"><i class="fab fa-facebook-f"></i></a>
            <a href="https://www.instagram.com/marketbey/" title="Instagram MarketBey" target="_blank"
                rel="noopenner"><i class="fab fa-instagram"></i></a>
            <a href="https://www.youtube.com/channel/UCw9pmPxIOLxJAko5rFWmfFQ" title="YouTube MarketBey" target="_blank"
                rel="noopenner"><i class="fab fa-youtube"></i></a>
        </div>
    </div>

</div>


<!-- </div> -->