import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { DetailsProductComponent } from './pages/details-product/details-product.component'
import { RegisterComponent } from './pages/register/register.component'
import { SearchComponent } from './pages/search/search.component';
import { CartComponent } from './pages/cart/cart.component';
import { AuthGuard } from './guard/auth.guard';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { PassCmdComponent } from './pages/pass-cmd/pass-cmd.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ForgetPassWordComponent } from './pages/forget-pass-word/forget-pass-word.component';
// import { PointCollectComponent } from './pages/point-collect/point-collect.component';
import { ServiceClientComponent } from './pages/service-client/service-client.component';
import { CommandesComponent } from './pages/commandes/commandes.component';
import { DetailsCmdComponent } from './pages/details-cmd/details-cmd.component';
import { AproposNousComponent } from './pages/apropos-nous/apropos-nous.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PaiementComponent } from './pages/paiement/paiement.component';
import { RetourComponent } from './pages/retour/retour.component';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { PoliticConfComponent } from './pages/politic-conf/politic-conf.component';


const routes: Routes = [

  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      { path: '', component: HomePageComponent },
      { path: 'details-produit', component: DetailsProductComponent },//////
      { path: 'cart', component: CartComponent },
      { path: 'service', component: ServiceClientComponent },
      { path: 'commande', component: CommandesComponent },/////////////
      { path: 'details-commande', component: DetailsCmdComponent },

      { path: 'checkout', component: CheckoutComponent, canActivate: [AuthGuard] },
      { path: 'pass-cmd', component: PassCmdComponent, canActivate: [AuthGuard] },
      { path: 'profil', component: ProfileComponent, canActivate: [AuthGuard] },

      { path: 'paiement', component: PaiementComponent },
      { path: 'retour', component: RetourComponent },
      { path: 'politique-de-confidentialite', component: PoliticConfComponent },

      { path: 'apropos-nous', component: AproposNousComponent },
      { path: 'contact', component: ContactComponent },
    ]
  },

  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: 'search', component: SearchComponent },

  // { path: 'pCollect', component: PointCollectComponent},

  { path: 'login', component: RegisterComponent },
  { path: 'forgetPW', component: ForgetPassWordComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
