<div class="main-top-bar">
    <div class="left">
        <div class="sm-menu-btn hidden-md hidden-lg" id="sm-menu-btn" (click)="openMenu()">
            <i class="fa fa-bars"></i>
        </div>
        <a class="logo hidden-xs hidden-sm" [routerLink]="[ '/']" title="MarketBey"><img src="assets/images/logo_blue.png" alt="images"
                height="80px" class="img-reponsive"></a>
        <div class="img-logo"></div>
    </div>
    <div class="right">
        <div class="hidden-xs hidden-sm">
            <!-- <form action="/search" class="search-form">
                <input type="text" name="s" class="form-control" placeholder="Rechercher des produits... " #keyword ngModel>
                <button type="button" class="search-icon" (click)="searchProduct(keyword)"></button>
            </form> -->
            <div class="search eOPDGP">
                <form action="" class="search-form" method="post" onsubmit="return false;"
                    (onsubmit)="searchProduct(searchText)">
                    <div>
                        <button type="submit" class="search-icon" (click)="searchProduct(searchText)"></button>
                    </div>

                    <input class="form-control" type="text" name="search" id="searchText" [(ngModel)]="searchText"
                        maxlength="100" autocomplete="off" placeholder="Vous cherchez quoi ?"
                        (ngModelChange)="filterNames($event)">

                </form>
                <div tabindex="0" class="cQCxPr hidden-xs hidden-sm" (click)="searchProduct(searchText)">Rechercher
                </div>
                <table class="table table-striped fEIYcM">
                    <tbody>
                        <tr *ngFor="let f of filterdName" (click)="searchProduct(f)">
                            <td>{{f}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- <a [routerLink]="[ '/login']" class="hidden-xs hidden-sm" title="login">
            <div class="item user">
                <img src="assets/images/avatar.png" alt="images" class="img-reponsive">
            </div>
        </a> -->

        <!-- <a [routerLink]="[ '/login']" class="hidden-xs hidden-sm space" title="login" *ngIf="isConnected==true">

            <div class="item-user">
                <ngx-avatar class="avatar" name="{{user.prenom}} {{user.nom}}" size=35 bgColor="#35b7ec"
                    fgColor="white">
                </ngx-avatar>
            </div>
        </a> -->


        <div class="menu-hover hidden-xs hidden-sm">
            <!-- <a [routerLink]="[ '/login']" title="login" *ngIf="isConnected==true"> -->
            <ngx-avatar class="avatar" name="{{user?.prenom_user}} {{user?.nom_user}}" size=35 bgColor="#35b7ec"
                fgColor="white">
            </ngx-avatar>
            <!-- </a> -->

            <div class="menu-level">
                <a class="profile" [routerLink]="[ '/profil']" title="profil">
                    <!-- <img alt="Hajer Romdhani" src="https://graph.facebook.com/10216075946844644/picture?width=100&amp;height=100"
                        class="ProfilePicture__ProfileImage-sc-19kphna-0 tHEql"> -->
                    <div class="item-user">
                        <ngx-avatar class="circle" name="{{user?.prenom_user}} {{user?.nom_user}}" size=40
                            bgColor="#35b7ec" fgColor="white">
                        </ngx-avatar>
                    </div>
                    <div class="profil-user">
                        <div class="profil-name">{{user?.prenom_user}} {{user?.nom_user}}</div>
                        <div class="profil-link">Afficher votre profil</div>
                    </div>
                    <div class="profil-svg">
                        <svg width="10" height="15" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"
                            style="transform: rotate(0deg); transition: all 0.25s ease 0s;">
                            <path d="M1 1l4 4.01L1 9" stroke="#192a32" stroke-width="1.5" fill="none"
                                fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">

                            </path>
                        </svg>
                    </div>
                </a>
                <a href="./" title="Home"><i class="fa fa-home"></i>Parcourir</a>
                <a [routerLink]="[ '/search']" title="Rechercher"><i class="fa fa-search"></i>Rechercher</a>
                <a [routerLink]="[ '/cart']" title="cart"><i class="fa fa-shopping-cart"></i>Panier</a>
                <a [routerLink]="[ '/commande']" title="commandes" *ngIf="isConnected==true"><i
                        class="fas fa-list-ul"></i>Historique de commandes</a>
                <a [routerLink]="[ '/service']" title="service"><i class="fa fa-comment-o"></i>Service client</a>
                <a [routerLink]="[ '/profil']" title="profil" *ngIf="isConnected==true"><i
                        class="fal fa-cog"></i>Paramètres</a>
                <a [routerLink]="[ '/login']" title="login" *ngIf="isConnected==false"><i class="fa fa-sign-in"></i>Se
                    connecter</a>
                <a title="login" *ngIf="isConnected==true" (click)="deconnexion()"><i
                        class="fa fa-sign-out"></i>Déconnexion</a>
            </div>
        </div>


        <!-- <div id="dd" class="wrapper-dropdown-3" tabindex="1">
            <span>Transport</span>
            <ul class="dropdown">
                <li><a href="#"><i class="icon-envelope icon-large"></i>Classic mail</a></li>
                <li><a href="#"><i class="icon-truck icon-large"></i>UPS Delivery</a></li>
                <li><a href="#"><i class="icon-plane icon-large"></i>Private jet</a></li>
            </ul>
        </div> -->


        <a [routerLink]="[ '/login']" class="btn-login hidden-xs hidden-sm" title="login" *ngIf="isConnected==false">
            <div> <i class="fal fa-user-alt"></i>
                Se connecter
            </div>
        </a>

       

        <a [routerLink]="[ '/search']" class="hidden-md hidden-lg" title="search">
            <div class="item search">
                <i class="search-icon"></i>
            </div>
        </a>
        
        <a [routerLink]="[ '/cart']" title="cart">
            <div class="item cart">
                <img src="assets/images/cart.png" alt="images" class="img-reponsive">
                <span *ngIf="nbrItem>0">{{nbrItem}}</span>
            </div>
        </a>
        <!-- <a title="notification" class="hidden-xs hidden-sm">
            <div class="item cart">
                <img src="assets/images/notification1.png" alt="images" class="img-reponsive">
            </div>
        </a> -->

        <div class="cQCxPr-aide hidden-xs hidden-sm" [routerLink]="[ '/service']" title="service">
            <span>
                <i class="fal fa-question-circle icon-help"></i>
            </span>
            Aide
        </div>
    </div>
</div>