<!-- <app-navbar></app-navbar>
<div class="page-content">
    <app-menu></app-menu>
    <main> -->
        <section class="checkout-page">
            <div class="container-fluid">
                <div class="heading-sub">
                    <ul class="other-link-sub">
                        <li><a [routerLink]="[ '/']" title="Acceuil">Acceuil</a></li>
                        <li>Politique de retour</li>
                    </ul>
                </div>

                <div class="complete-page text-center">
                    <h2 class="status">
                        <a class="logo" [routerLink]="[ '/']" title="MarketBey"><img
                                src="assets/images/logo_blue.png" alt="images" height="80px" class="img-reponsive"></a>

                        <!-- <span class="iconify" data-icon="ion-checkmark-circled" data-inline="false"></span> -->
                        <br /><br />
                        Retour & remboursement
                    </h2>
                </div>


                <div class="desc-mb">
                    <p>MarketBey votre site de vente en ligne en Tunisie avec la mise en œuvre des meilleures pratiques
                        en ligne et hors ligne .
                        MarketBey a mis en place des procédures simplifiées pour vous assurer des achats en toute
                        tranquillité et vous permettre de retourner votre produit dans les meilleures conditions,
                        notamment :Si celui-ci est en défaillance et que vous souhaitez exercer votre droit de
                        rétractation en effectuant une demande de retour.
                        Si votre produit n'est pas conforme à celui que vous avez commandé lors de la vente.
                        <br />
                        Nous vous accompagnons tout au long de votre démarche de retour afin de vous assurer un suivi
                        simple et fiable. Les produits retournés sont vérifiés par notre service logistique lors de la
                        réception. S'ils remplissent les critères préalables au remboursement, la demande de
                        remboursement sera effectuée.
                    </p>
                    <br />
                </div>



                <div>
                    <span><b>Comment retourner un produit ?</b></span>
                    <p>
                        En cas de problème avec le produit, vous pouvez demander le retour de ce dernier en nous
                        contactant sur la page et en précisant la nature du problème accompagné d’une photo en guise de
                        preuve. Ou cliquer sur le bouton réclamation sur votre compte MarketBey.
                    </p>
                    <span><b>Quel est le délai de retour ? </b></span>
                    <p>
                        Après un achat chez MarketBey, vous pouvez retourner votre produit dans la plupart des cas dans
                        un délai de 10 jours à partir du jour de la réception du colis.
                    </p>
                    <span><b>Quelles sont les conditions pour retourner un produit ?</b></span>
                    <p>
                        Lorsque vous retournez votre article, il doit être rendu à MarketBey dans exactement la même
                        condition que lorsqu’il vous a été livré, et avec tous ses accessoires, ceci sera vérifié par le
                        livreur avant de reprendre le colis.
                        <br />
                        Les produits non éligibles au retour:
                    
                    </p>
                    <ul>
                        <li>- Produits de beauté ( Catégories Maquillage) </li>
                        <li>- Parfums </li>
                    </ul>
                    <br/>
                    <span><b>Tout produit retourné sera sujet à un examen.</b></span>
                    <p>
                        Si l’examen est positif, MarketBey vous remboursera selon le mode choisi.<br />
                        Si l’examen est négatif, vous ne serez pas remboursé et votre produit acheté en ligne vous sera
                        retourné.<br />
                        Le remboursement aura lieu entre 10 et jours (tout dépend de la zone de retrait) qui suivent
                        votre demande de retour.<br />
                        Le remboursement aura lieu entre 10 et 30 jours (tout dépend de la zone de retrait) qui suivent
                        votre demande de retour.

                    </p>
                    <span><b>Dois-je payer des frais de transport en cas de retour ?</b></span>
                    <p>
                        Des frais de retours sont appliqués comme suit :<br />
                    
                    </p>
                    <ul>
                        <li>* 5dt pour le grand Tunis.</li>
                        <li>* 7dt Hors Tunis.</li>
                    </ul>

                </div>
            </div>
        </section>
    <!-- </main>
</div>
<app-footer></app-footer> -->
<!-- <footer>
    <div class="copyright">
        <div class="container-fluid">
            <span>© <a href="#" title="">MarketBey</a> - All Rights Reserved.</span>

        </div>
    </div>
</footer> -->