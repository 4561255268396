
<!-- <div class="page-content">
    <app-menu></app-menu>
    <main> -->
        <section class="checkout-page">
            <div class="container-fluid">
                <div class="heading-sub">
                    <ul class="other-link-sub">
                        <li><a [routerLink]="[ '/']" title="Acceuil">Acceuil</a></li>
                        <li>Contactez-nous</li>
                    </ul>
                </div>
                <!-- <ul class="breadcrumb">
                    <li>
                        <a href="checkout-1.html">01. Panier</a>
                    </li>
                    <li>
                        <a href="checkout-2.html">02. Livraison</a>
                    </li>
                    <li>
                        <a class="active" href="checkout-3.html" title="">03. Commande</a>
                    </li>
                </ul> -->
                <div class="complete-page text-center">
                    <h2 class="status">
                        <a class="logo" [routerLink]="[ '/']" title="MarketBay"><img
                                src="assets/images/logo_blue.png" alt="images" height="80px" class="img-reponsive"></a>

                        <!-- <span class="iconify" data-icon="ion-checkmark-circled" data-inline="false"></span> -->
                        <br /><br />
                       Contactez-nous
                    </h2>
                </div>


                <div class="desc-mb">
                    <p> Les équipes Marketbey votre site de vente en ligne sont toujours à votre écoute. Si vous avez
                        besoin d’informations relatives à votre commande en ligne, ou pour tout renseignement; vous
                        pouvez nous contacter directement par email,
                        téléphone ou courrier. Pour ne manquer aucune actualité, suivez-nous sur nos réseaux
                        sociaux.<br />
                       </p>
                    <br />
                </div>

                <div>
                    <ul>
                        <li>1) Numéro de téléphone : (+216) 27 305 100</li>
                        <li>2) E-mail : Contact@marketbey.tn </li>
                    </ul>
                    <br />
                      


                </div>

                
            </div>
        </section>
    <!-- </main>
</div> -->
<!-- <app-footer></app-footer> -->
<!-- <footer>
    <div class="copyright">
        <div class="container-fluid">
            <span>© <a href="#" title="">MarketBey</a> - All Rights Reserved.</span>

        </div>
    </div>
</footer> -->