import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// import { GoogleAnalyticsService } from './services/google-analytics.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'website-marketbey';

  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-3V50GWNQXT',
          {
            '/': event.urlAfterRedirects
          })
      }
    })
  }
}
