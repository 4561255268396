<!-- <app-navbar nbrItem="{{ nbrItem }}"></app-navbar>
<div class="page-content">
    <app-menu></app-menu>
    <main> -->
        <section class="checkout-page">
            <div class="container-fluid">
                <div class="heading-sub">
                    <ul class="other-link-sub">
                        <li><a [routerLink]="[ '/']" title="Acceuil">Acceuil</a></li>
                        <li><a [routerLink]="[ '/cart']" title="Panier">Panier</a></li>
                        <li><a class="active" href="#">Livraison</a></li>
                    </ul>
                </div>
                <ul class="breadcrumb">
                    <li>
                        <a [routerLink]="[ '/cart']" title="Panier">01. Panier</a>
                    </li>
                    <li>
                        <a class="active">02. Livraison</a>
                    </li>
                    <li>
                        <a title="">03. Commande</a>
                    </li>
                </ul>
                <div class="orders">
                    <div class="row">
                        <div class="col-xs-12 col-md-7">
                            <!-- <div class="login-required">
                                <p>Retour Client? Cliquer <a href="#" title="" class="login">ICI</a> pour connecter
                                </p>
                                <p>Avez-vous un coupon? Cliquer <a href="#" title="" class="coupon-code">ICI</a> Pour entrer
                                    ton code
                                </p>
                            </div> -->
                            <div class="billing-details">
                                <div class="billing-details-heading">
                                    <h2 class="billing-title">
                                        Vérifiez vos informations
                                    </h2>
                                </div>
                                <div class="billing-details-content">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12">
                                                <strong>Nom *</strong>
                                                <input type="text" name="prenom" class="form-control"
                                                    [(ngModel)]="user.prenom_user" disabled>
                                            </div>
                                            <div class="col-sm-6 col-xs-12">
                                                <strong>Prénom *</strong>
                                                <input type="text" name="name" class="form-control"
                                                    [(ngModel)]="user.nom_user" disabled>

                                                <!-- <input matInput placeholder="Vehicle Number" class="form-control" name="user.nom" 
                                                    [(ngModel)]="user.nom"> -->

                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <strong>Company Name</strong>
                                                <input type="text" name="company_name" class="form-control"
                                                    value="">
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12">
                                                <strong>Adresse Email *</strong>
                                                <input type="text" name="email" class="form-control"
                                                    [(ngModel)]="user.email_user" disabled>
                                            </div>
                                            <div class="col-sm-6 col-xs-12">
                                                <strong>Téléphone *</strong>
                                                <input type="text" name="numtel" class="form-control"
                                                    [(ngModel)]="user.tel_user">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <strong>Country *</strong>
                                                <select name="CountryType" id="country" class="form-control">
                                                    <option value="1">Viet Nam</option>
                                                    <option value="2">USA</option>
                                                    <option value="3">Germany</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <strong>Adresse *</strong>
                                                <input type="text" name="adresse" class="form-control"
                                                    [(ngModel)]="user.last_shipping_address.adresse">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6 col-xs-12">
                                                <strong>Code postal</strong>
                                                <input type="text" name="codepostal" class="form-control"
                                                    [(ngModel)]="user.last_shipping_address.codepostal">
                                            </div>
                                            <div class="col-md-6 col-xs-12">
                                                <strong>Ville *</strong>
                                                <!-- <input type="text" name="ville" class="form-control"
                                                    [(ngModel)]="user.last_shipping_address.ville"> -->
                                                <select name="ville" id="ville" class="form-control selectric"
                                                    [(ngModel)]="user.last_shipping_address.ville"
                                                    (change)="selectChangeVille($event)">
                                                    <option *ngFor="let ville of villes" [ngValue]="ville"
                                                        ngDefaultControl>{{ville}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <input type="checkbox" name="acc"> Create an account?
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <strong>Order Note</strong>
                                                <textarea name="note" id="address" tabindex="2"
                                                    class="form-control form-textarea"></textarea>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="shopping-cart-option">
                                <div class="shopping-cart-coupon" *ngIf="nbrItem > 0">
                                    <div class="row">
                                        <div class="col-md-7 col-sm-12">
                                            <form action="#" method="POST" class="coupon-form">
                                                <div class="form-group">
                                                    <input type="text" name="promo" id="promo" class="form-control"
                                                        placeholder="Code promo" #promo ngModel>
                                                    <span class="icon-coupon"></span>
                                                </div>
                                                <button type="submit" class="btn-submit"></button>
                                            </form>
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                            <button (click)="checkCoupon(promo)" [disabled]="isCheckCoupon"
                                                class="btn-update-cart">Appliquer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-5">
                            <div class="aside-order">
                                <h2>Ma commande</h2>
                                <table class="table table-product">
                                    <thead>
                                        <tr>
                                            <th>PRODUIT</th>
                                            <th></th>
                                            <th>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let product of allItems">
                                            <td class="product-name">{{product?.name}}
                                                <span *ngFor="let variation of product?.selectedVariation | objNgFor">
                                                    <div>{{variation.value}}</div>
                                                </span>
                                            </td>
                                            <td class="product-quantity">x {{product?.quantite}}</td>
                                            <td class="product-total">{{product?.price * product?.quantite}} DT</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ul class="tabs-first">
                                    <li><span class="text">Prix article(s):</span><span
                                            class="cart-number sub-number pull-right">{{totalPrice | number:'0.3'}} DT</span></li>
                                    <li><span class="text">Livraison:</span>
                                        <div class="shipping">
                                            <form action="#">
                                                <input type="radio" name="livraison" value="{{priceLivraison | number:'0.3'}}"
                                                    id="livraisonStandard" checked="checked"
                                                    (change)="checkPriceLivraison($event)">
                                                <label for="livraisonStandard">Livraison Standard:
                                                    <strong>{{priceLivraison | number:'0.3'}} DT</strong>
                                                </label>

                                                <!-- <a [routerLink]="[ '/pCollect']" title="collect"><input type="radio"
                                                        name="livraison"
                                                        value="{{choiceLivraison.livraisonStandardGrandTunis}}"
                                                        id="livraisonStandardGrandTunis"
                                                        (change)="checkPriceLivraison($event)">
                                                </a>
                                                <label for="livraisonStandardGrandTunis">point de relais
                                                    sur le grand Tunis: {{choiceLivraison.livraisonStandardGrandTunis}}
                                                    DT
                                                </label> -->

                                                <!-- <input type="radio" name="gender" value="Delivery" id="radio3">
                                                <label for="radio3">FLAT RATE: <span
                                                        class="shipping-number">$60.00</span></label>
                                                <input type="radio" name="gender" value="Local-Delivery"
                                                    id="radio4">
                                                <label for="radio4">LOCAL DELIVERY: <span
                                                        class="shipping-number indent">$5.00</span></label> -->
                                            </form>
                                        </div>
                                        <div class="clearfix"></div>
                                    </li>
                                    <li>
                                        <span class="text">Montant total:</span>
                                        <span class="cart-number big-total-number pull-right">
                                            {{priceLivraison + totalPrice | number:'0.3'}} DT
                                        </span>
                                    </li>
                                    <li>
                                        <span class="cmd-number text" *ngIf="amountCoupon != 0 ">Montant total après
                                            remise:</span>
                                        <span class="cmd-number big-total-number pull-right" *ngIf="amountCoupon != 0 ">
                                            {{priceLivraison + totalPrice - amountCoupon | number:'0.3'}} DT
                                        </span>
                                    </li>
                                </ul>
                                <!-- <div class="order-transfer clearfix">
                                    <ul class="tabs">
                                        <li class="active">
                                            <i class="icon"></i>
                                            <h4>Direct Bank Transfer
                                                <span>Make your payment directly into our bank account. Please use
                                                    your
                                                    Order ID as the payment reference. Your order won’t be shipped
                                                    until the
                                                    funds have cleared in our account. </span>
                                            </h4>
                                        </li>
                                        <li>
                                            <i class="icon"></i>
                                            <h4>Check Payments</h4>
                                        </li>
                                        <li>
                                            <i class="icon"></i>
                                            <h4>Cash On Delivery</h4>
                                        </li>
                                    </ul>
                                </div> -->
                                <button type="button" class="btn-order" (click)="addCommande()">Valider la
                                    commande</button>
                                <label class="msg-error" *ngIf="msgErrorForm!=null">{{msgErrorForm}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    <!-- </main>
</div>
<app-footer></app-footer> -->
<!-- <footer>
    <div class="copyright">
        <div class="container-fluid">
            <span>© <a href="#" title="">MarketBey</a> - All Rights Reserved.</span>

        </div>
    </div>
</footer> -->