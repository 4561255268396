import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService {

  constructor(private analytics: AngularFireAnalytics) { }

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue
    })
  }

  public addToCart(itemId: string, itemName: string, itemCategory: string, quantity: number, price: number) {
    this.analytics.logEvent('add_to_cart', {
      item_id: itemId,
      item_name: itemName,
      item_category: itemCategory,
      quantity: quantity,
      price: price

    })




  }

  public setUserProperties(userId: string, userName: string, userMail: string) {
    this.analytics.setUserId(userId)
    this.analytics.setUserProperties({
      'user_name': userName,
      'user_mail': userMail
    })
  }

  public viewItem(itemId: string, itemName: string, itemCategory: string, quantity: number, price: number) {
    this.analytics.logEvent('view_item', {
      item_id: itemId,
      item_name: itemName,
      item_category: itemCategory,
      quantity: quantity,
      price: price
    }
    )




  }


}
