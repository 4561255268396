<div class="AuthPageContainer">
  <div class="BackGroundContainer">

  </div>
  <div class="AuthInfoContainer">
    <div class="AuthInfoContent">
      <div class="logoContainer">
        <!-- <img src="assets/images/logo_blue.png" alt="companyLogo" /> -->
      </div>
      <div class="authTitles">
        <!-- <div class="col-md-12 col-xs-12">
          <ul class="nav-register connexion" role="tablist">
            <li *ngFor="let conx of typeConnexion; let first = first;" [ngClass]="{firstItem: first}"
              role="presentation" class="nav-item nav-link active" [class.active]="selectedConx===conx">
              <a (click)="selectedConx = conx" aria-controls="login" role="tab"
                [class]="selectedConx==conx ? 'dVycJd' : ''" data-toggle="tab">{{conx}}</a>
            </li>
          </ul>
        </div> -->
        <a (click)="changePageLogin()" [ngClass]="(tagConnexion=='login')?'loginText':''">Se connecter</a>
        <a (click)="changePageSignUp()" [ngClass]="(tagConnexion=='sign')?'signUpText':''">S'inscrire</a>
      </div>
      <div class="authInputs" id="login">
        <div *ngIf="tagConnexion=='login'">
          <form action="" class="sub-form" method="post" onsubmit="return false;" (onsubmit)="authentification();">
            <input type="text" class="form-control" placeholder="Adresse email" name="username" id="username" #username
              required="required" [(ngModel)]="userInscrir.username" />
            <label class="msg-error" *ngIf="msgErrorEmail!=null">{{msgErrorEmail}}</label>

            <input type="password" class="form-control" placeholder="Mot de passe" name="password" id="password"
              #password required="required" [(ngModel)]="userInscrir.password" />
            <label class="msg-error" *ngIf="msgErrorPassword!=null">{{msgErrorPassword}}</label> <br />


            <a [routerLink]="[ '/forgetPW']" title="Mot de passe oublié">Mot de passe oublié ?</a>
            <button type="submit" class="authButton" (click)="authentification()">Se connecter</button>
            <label class="msg-error" *ngIf="msgErrorLogin!=null">{{msgErrorLogin}}</label>
            <p>Ou</p>
            <button class="otherAuthButton facebook" (click)="signInWithFB()">
              <i class="fab fa-facebook"></i> Facebook
            </button>
            <!-- <button class="otherAuthButton google">
            <i class="fab fa-google"></i>Google
          </button>
          <button class="otherAuthButton apple">
            <i class="fab fa-apple"></i>Apple
          </button> -->
            <!-- <h2>
            By clicking 'Log In', 'Facebook', 'Google' or 'Apple' you agree to
            <span> the Wish Terms of Use and Privacy Policy</span> . This site
            is protected by reCAPTCHA and the Google
            <span>Privacy Policy and Terms of Service</span> apply.
          </h2> -->
          </form>
        </div>

        <div *ngIf="tagConnexion=='sign'">
          <form action="" class="sub-form" method="post" onsubmit="return false;" (onsubmit)="inscription();">


            <input type="text" class="form-control" placeholder="Prénom" name="first_name" id="first_name"
              [(ngModel)]="userInscrir.prenom" />
            <label class="msg-error" *ngIf="msgErrorFirstname!=null">{{msgErrorFirstname}}</label>

            <input type="text" class="form-control" placeholder="Nom" name="last_name" id="last_name"
              [(ngModel)]="userInscrir.nom" />
            <label class="msg-error" *ngIf="msgErrorLastName!=null">{{msgErrorLastName}}</label>

            <input type="text" class="form-control" placeholder="Adresse email" name="email" id="email"
              [(ngModel)]="userInscrir.email" />
            <label class="msg-error" *ngIf="msgErrorEmail!=null">{{msgErrorEmail}}</label>

            <input type="password" class="form-control" placeholder="Mot de passe" name="motpasse" id="motpasse"
              [(ngModel)]="userInscrir.password" />
            <label class="msg-error" *ngIf="msgErrorPassword!=null">{{msgErrorPassword}}</label>

            <!-- <input type="text" class="form-control" placeholder="Adresse" name="adresse" id="adresse"
              [(ngModel)]="userInscrir.adresse" /> -->
            <!-- <input type="text" class="form-control" placeholder="Ville" name="ville" id="ville" #ville ngModel /> -->

            <!-- <select class="form-control" name="ville" id="ville" [(ngModel)]="userInscrir.ville">
              <option *ngFor="let ville of villes" [ngValue]="ville" [ngModelOptions]="{standalone: true}">
                {{ville}}
              </option>
            </select> -->

            <!-- <select name="CountryType" id="country" class="form-control selectric"
              [(ngModel)]="user.last_shipping_address.ville">
              <option *ngFor="let ville of villes" [ngValue]="ville" ngDefaultControl>{{ville}}
              </option>
            </select> -->

            <input type="text" class="form-control" placeholder="Numéro de téléphone" name="numtel" id="numtel"
              [(ngModel)]="userInscrir.numtel" />
            <label class="msg-error" *ngIf="msgErrorNumtel!=null">{{msgErrorNumtel}}</label>

            <button class="authButton" (click)="inscription()">S'inscrire</button>
            <label class="msg-error" *ngIf="msgErrorInscrir!=null">{{msgErrorInscrir}}</label>
            <p>Ou</p>

            <button type="submit" class="otherAuthButton facebook">
              <i class="fab fa-facebook"></i> Facebook
            </button>
            <!-- <button class="otherAuthButton google">
              <i class="fab fa-google"></i>Google
              </button>
              <button class="otherAuthButton apple">
              <i class="fab fa-apple"></i>Apple
            </button> -->
            <!-- <h2>
              By clicking 'Log In', 'Facebook', 'Google' or 'Apple' you agree to
              <span> the Wish Terms of Use and Privacy Policy</span> . This site
              is protected by reCAPTCHA and the Google
              <span>Privacy Policy and Terms of Service</span> apply.
            </h2> -->
          </form>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- <form action="#" class="sub-form" method="post" onsubmit="return false;"
  (onsubmit)="authentification(username,password);">
  <input type="text" name="username" id="username" class="form-control" placeholder="Email" #username ngModel />
  <input type="text" name="password" id="password" class="form-control" placeholder="Mot de passe" #password ngModel />

  <button type="submit" class="btn btn-sub" (click)="authentification(username,password)">Se connecter<span
      class="ion-chevron-right"></span>

  </button>
</form> -->