import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ProductsService } from 'src/app/services/products/products.service';
import { CartService } from 'src/app/services/cart/cart.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';


@Component({
  selector: 'app-details-product',
  templateUrl: './details-product.component.html',
  styleUrls: ['./details-product.component.css']
})

export class DetailsProductComponent implements OnInit {
  @ViewChild('detailsproduct', { static: false }) commandeCtn: ElementRef;

  nbrItem: number;

  prdID: string = '';
  basePathImage: any = environment.baseImagesURL;

  basePathVideo: any = environment.baseVideoURL;

  photoProduct: string = ""
  videoProduct: string = ""
  product: any = {
    prd: {},
    galeries: {},
    variations: {}
  };
  cat: any = {}
  finished = false;
  catsList: any = []

  selectedVariation: any = {}

  msgError: string = null;

  constructor(
    private route: ActivatedRoute,
    private prdServices: ProductsService,
    private cartService: CartService,
    private dialog: MatDialog,
    private analyticsService : GoogleAnalyticsService
  ) { }

  async ngOnInit() {
    window.scrollTo(0, 0);

    await this.route.queryParams.subscribe(async (params) => {
      this.prdID = params.param;
      await this.getProduct(this.prdID);
      await this.getCat()
      await this.remplirVariation()
    })
    await this.listCats();
    // console.log("prdID");
    // console.log(this.prdID);
  }

  async getCat() {
    if (this.product?.prd?.prox_cat_produits[0] != null) {
      await this.prdServices.getCat(this.product?.prd?.prox_cat_produits[0]).then((res: any) => {
        if (res) {
          this.cat = res;
        }
        //console.log("list categories");
        //console.log(res);
      });
    }
  }

  async listCats() {
    await this.prdServices.getCats().then((res: any) => {
      if (res) {
        this.catsList = res;
      }
    });
  }

  remplirVariation() {
    this.product.prd.variations.forEach((e: any) => {
      this.selectedVariation[e.label] = e.valeurs[0]
    });
  }

  selectedVariations(event: any, label: string) {
    //update the ui
    this.selectedVariation[label] = event.target.value;

  }

  async getProduct(prdID) {
    await this.prdServices.getProduct(prdID).then(async (res: any) => {
      if (res) {
        this.product = res;
        this.photoProduct = this.basePathImage + this.product.prd.photo_produit
        this.videoProduct = this.basePathVideo + this.product.prd.video

        //this.analyticsService.eventEmitter("view_item", this.product.prd.category, this.product.prd._id, this.product.prd.nom_produit, this.product.prd.prix_produit)
        //console.log("videoProduct");
        //console.log(this.videoProduct);
        //console.log("this.product");
        //console.log(this.product);

      } else {
        alert('Aucun produit');
      }
    });
  }

  hoverPhoto(photo: string) {
    this.photoProduct = this.basePathImage + photo
  }

  async addToCart() {
    this.msgError = null;
    var item = {
      id: this.product.prd._id,
      // category: this.product.prd.category,/////
      // currency: this.product.prd.currency,//////
      description: this.product.prd.description_produit,
      name: this.product.prd.nom_produit,
      image: this.product.prd.photo_produit,
      // isVariable: this.product.prd.isVariable,/////
      selectedVariation: this.selectedVariation,//////
      quantite: 1,
      price: this.product.prd.prix_produit,
      // pricetxt: this.product.prd.pricetxt,////////
      quantiteLimite: this.product.prd.quantiteLimite,
      tested: this.product.prd.tested,
      isDelivery: this.product.prd.isDelivery
    }

    if (this.cartService.isOneProductFree() && item.price=="0") {
      this.msgError = "vous avez déjà ajouté un produit gratuit"
    } else if(this.product.prd.stock_reelle <= 0) {
      this.msgError = "Quantité épuisée !"
    } else {
      this.cartService.addToCart(item)
      this.dialog.open(ModalComponent);
      this.nbrItem = await this.cartService.getNbrItems()
      this.analyticsService.addToCart(this.product.prd._id, this.product.prd.nom_produit, this.product.prd.category, this.product.prd.quantite, this.product.prd.prix_produit)
    }
  }

  // openDialog() {
  //   this.dialog.open(ModalComponent);
  // }
}
