import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';
import { SocialAuthService, FacebookLoginProvider, SocialUser } from "angularx-social-login";
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {

  villes = ['Ariana',
    'Béja',
    'Ben Arous',
    'Bizerte',
    'Gabès',
    'Gafsa',
    'Jendouba',
    'Kairouan',
    'Kasserine',
    'Kébili',
    'Kef',
    'Mahdia',
    'Manouba',
    'Médenine',
    'Monastir',
    'Nabeul',
    'Sfax',
    'Sidi Bouzid',
    'Siliana',
    'Sousse',
    'Tataouine',
    'Tozeur',
    'Tunis',
    'Zaghouan'
  ];

  tagConnexion = "login"

  userInscrir: any = {
    "nom": "",
    "prenom": "",
    "username": "",
    "email": "",
    "adresse": "",
    "ville": "",
    "password": "",
    "numtel": "",
  }

  msgErrorFirstname: string = null;
  msgErrorLastName: string = null;
  msgErrorEmail: string = null;
  msgErrorPassword: string = null;
  msgErrorNumtel: string = null;
  msgErrorInscrir: string = null
  msgErrorLogin: string = null

  user: SocialUser;
  loggedIn: boolean;
  detailsUser: any = {}
  constructor(private authService: UsersService,
     private router: Router, 
     private fbService: SocialAuthService, private analyticsService: GoogleAnalyticsService) {

  }


  ngOnInit(): void {
    window.scrollTo(0, 0);

    if (this.authService.isConnected()) {
      this.router.navigate(['/'])
    }

    this.fbService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
      //console.log("login facebook")
      //console.log(this.user);
    });

  }

  changePageLogin = () => {
    this.tagConnexion = "login"
  }

  changePageSignUp = () => {
    this.tagConnexion = "sign"
  }

  authentification() {
    this.initMsgError();
    //console.log(username.value, password.value);
    if (this.userInscrir.username == "" || !this.userInscrir.username.includes("@") || this.userInscrir.password == "") {
      if (!this.userInscrir.username.includes("@")) {
        this.msgErrorEmail = "Email est requis et doit contenir '@'";
      }

      if (this.userInscrir.password == "") {
        this.msgErrorPassword = "Le mot de passe est requis";
      }
    } else {
      this.authService.login(this.userInscrir).then((res: any) => {
        //console.log("login user")
        //console.log(res)
        if (res) {
          if (res?.msg) {
            //console.log("inscrir user")
            //console.log(res.msg)
            this.msgErrorLogin = res.msg
          } else {
            this.detailsUser = res
            // console.log("this.detailsUser")
            // console.log(this.detailsUser)

            location.href = '/';
            
          }
        }
        else {
          // console.log("erreur")
        }
      })
    }

  }

  initMsgError() {
    this.msgErrorFirstname = null;
    this.msgErrorLastName = null;
    this.msgErrorEmail = null;
    this.msgErrorPassword = null;
    this.msgErrorNumtel = null;
    this.msgErrorInscrir = null
    this.msgErrorLogin = null
  }
  inscription() {
    //console.log("this.userInscrir")
    //console.log(this.userInscrir)
    this.initMsgError();
    if (this.userInscrir.prenom == "" || this.userInscrir.nom == "" || this.userInscrir.email == "" ||
      !this.userInscrir.email.includes("@") || this.userInscrir.password == "" || this.userInscrir.numtel == "") {
      if (this.userInscrir.prenom == "") {
        this.msgErrorFirstname = "Le prénom est requis";
      }
      if (this.userInscrir.nom == "") {
        this.msgErrorLastName = "Le nom est requis";
      }
      if (this.userInscrir.email == "" || !this.userInscrir.email.includes("@")) {
        this.msgErrorEmail = "L'email n'est pas validé";
      }
      if (this.userInscrir.password == "") {
        this.msgErrorPassword = "Le mot de passe est requis";
      }
      if (this.userInscrir.numtel == "") {
        this.msgErrorNumtel = "Le numéro de téléphone est requis";
      }
    } else {
      this.userInscrir.username = this.userInscrir.email
      //console.log("this.userInscrir elese")
      //console.log(this.userInscrir)
      this.authService.register(this.userInscrir).then((res: any) => {
        if (res) {
          if (res?.msg) {
            //console.log("inscrir user")
            //console.log(res.msg)
            this.msgErrorInscrir = res.msg
          } else {
            //console.log("inscrir user")
            //console.log(res)

            location.href = '/';
          }
        }
        else {
          // console.log("erreur")
        }
      })
    }
  }

  loginFB() {
    if (this.user != null) {
      var creds = {
        "nom": this.user.lastName,
        "prenom": this.user.firstName,
        "username": this.user.email,
        "email": this.user.email
      };

      this.authService.register(creds, "fb").then((res: any) => {
        if (res) {
          //console.log("res FB :")
          //console.log(res)
          location.href = '/';
        }
        else {
          // console.log("erreur")
        }
      })
    }
  }

  async signInWithFB() {
    await this.fbService.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.loginFB()
  }

  signOut(): void {
    this.fbService.signOut();
  }
}
