<!-- <app-navbar nbrItem="{{ nbrItem }}"></app-navbar>
<div class="page-content">
    <app-menu></app-menu>
    <main> -->
        <section class="checkout-page">
            <div class="container-fluid">
                <div class="heading-sub">
                    <ul class="other-link-sub">
                        <li><a [routerLink]="[ '/']" title="Acceuil">Acceuil</a></li>
                        <li><a class="active" [routerLink]="[ '/cart']" title="Panier">Panier</a></li>
                    </ul>
                </div>
                <ul class="breadcrumb">
                    <li>
                        <a class="active">01. Panier</a>
                    </li>
                    <li>
                        <a>02. Livraison</a>
                    </li>
                    <li>
                        <a title="">03. Commande</a>
                    </li>
                </ul>

                <div class="checkout-cart-form">
                    <div class="row">
                        <div class="col-md-8 col-sm-12 col-xs-12">
                            <div class="impty-cart" *ngIf="nbrItem == 0">
                                <div class="koBNEX">
                                    <div class="jvVaXU">
                                        <img src="assets/images/cart.png" alt="images" class="img-reponsive">
                                    </div>

                                    <div class="jQjFrX">

                                        Votre panier est vide !

                                    </div>
                                </div>



                            </div>

                            <table class="table shop_table" *ngIf="allItems?.length>0">
                                <thead>
                                    <tr>
                                        <th class="product-thumbnail">IMAGE</th>
                                        <th class="product-name">NOM PRODUIT</th>
                                        <th class="product-price">PRIX</th>
                                        <th class="quantity">QUANTITE</th>
                                        <th class="product-subtotal">TOTAL</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="cart_item" *ngFor="let product of allItems">
                                        <td class="product-thumbnail"><a [routerLink]="[ '/details-produit']"
                                                title="Détails produit" [queryParams]="{ param: product?.id }">
                                                <img src="{{basePathImage + product?.image}}" alt="images"
                                                    class="img-responsive"></a></td>
                                        <td class="product-name">
                                            <a [routerLink]="[ '/details-produit']" title="Détails produit"
                                                [queryParams]="{ param: product?.id }">{{product?.name}}</a> <br />
                                    <tr *ngFor="let variation of product?.selectedVariation | objNgFor">
                                        <td>{{variation.key}} {{variation.value}}</td>
                                    </tr>
                                    </td>
                                    <td class="product-price">
                                        <p class="price">{{product?.price}} DT</p>
                                    </td>
                                    <td class="product-quantity">
                                        <form class="cart">
                                            <div class="quantity">
                                                <button *ngIf="product.price!='0'" type="button" class="quantity-left-minus btn btn-number"
                                                    data-type="minus" data-field="" (click)="minuxFromCart(product)">
                                                    <span class="minus-icon"></span>
                                                </button>
                                                <input type="number" name="number" value="{{product?.quantite}}"
                                                    id="quantity">
                                                <button *ngIf="product.price!='0'" type="button" class="quantity-right-plus btn btn-number"
                                                    data-type="plus" data-field="" (click)="addToCart(product)" >
                                                    <span class="plus-icon"></span>
                                                </button>
                                            </div>
                                        </form>
                                        <!-- <div class="quantity">
                                                <button type="button" class="quantity-left-minus btn btn-number"
                                                    data-type="minus" data-field="">
                                                    <span class="minus-icon"></span>
                                                </button>
                                                <input type="text" name="quantity" value="1">
                                            </div> -->
                                    </td>
                                    <td class="product-price product-subtotal">
                                        <p class="price">{{product?.price * product?.quantite}} DT</p>
                                    </td>
                                    <td class="product-action">
                                        <i class="fa fa-trash fa-lg text-danger" (click)="removeFromCart(product)"></i>
                                    </td>
                                    </tr>
                                    <!-- <tr class="cart_item">
                                        <td class="product-thumbnail"><a href="#"><img
                                                    src="https://picsum.photos/id/133/800" alt="images"
                                                    class="img-responsive"></a></td>
                                        <td class="product-name">
                                            <a href="#">Canon EOS 6D 20.2 MP CMOS Digital SLR Camera</a>
                                        </td>
                                        <td class="product-price">
                                            <p class="price">$99.00</p>
                                        </td>
                                        <td class="product-quantity">
                                            <div class="quantity">
                                                <input type="text" name="qtt" value="1">
                                            </div>
                                        </td>
                                        <td class="product-price product-subtotal">
                                            <p class="price">$99.00</p>
                                        </td>
                                    </tr>
                                    <tr class="cart_item">
                                        <td class="product-thumbnail"><a href="#"><img
                                                    src="https://picsum.photos/id/133/800" alt="images"
                                                    class="img-responsive"></a></td>
                                        <td class="product-name">
                                            <a href="#">Canon EOS 6D 20.2 MP CMOS Digital SLR Camera</a>
                                        </td>
                                        <td class="product-price">
                                            <p class="price">$140.00</p>
                                        </td>
                                        <td class="product-quantity">
                                            <div class="quantity">
                                                <input type="text" name="qtt" value="1">
                                            </div>
                                        </td>
                                        <td class="product-price product-subtotal">
                                            <p class="price">$140.00</p>
                                        </td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <div class="aside-shopping-cart-total">
                                <h2>Résumé de la commande</h2>
                                <ul class="tabs-first">
                                    <li><span class="text">Nombre article(s):</span><span
                                            class="cart-number">{{allItems.length}}</span>
                                    </li>
                                    <!-- <li><span class="text">Livraison:</span>
                                        <div class="shipping">
                                            <form method="#" action="/search" role="search">

                                                <input type="radio" name="livraison" value="{{choiceLivraison.livraisonStandard}}"
                                                    id="livraisonStandard" checked="checked" (change)="checkPriceLivraison($event)">
                                                <label for="livraisonStandard">Livraison standard: {{choiceLivraison.livraisonStandard}} DT</label>

                                                <input type="radio" name="livraison" value="{{choiceLivraison.livraisonStandardGrandTunis}}"
                                                    id="livraisonStandardGrandTunis" (change)="checkPriceLivraison($event)">
                                                <label for="livraisonStandardGrandTunis">Point de relais sur le grand Tunis: {{choiceLivraison.livraisonStandardGrandTunis}} DT</label>

                                            </form>
                                        </div>
                                    </li> -->
                                    <!-- <li><span class="text calculate">Calculate shipping</span>
                                    </li> -->
                                    <li><span class="text">Montant total:</span>
                                        <span class="cart-number big-total-number">{{totalPrice}} DT</span>
                                    </li>
                                </ul>
                                <div class="process">
                                    <button type="button" class="btn-checkout" (click)="checkout()">Livraison</button>
                                    <label class="msg-error" *ngIf="msgErrorCart!=null">{{msgErrorCart}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="shopping-cart-option">
                        <div class="button-option" *ngIf="nbrItem == 0">
                            <a [routerLink]="[ '/']" title="Acceuil" class="btn-continue-shopping active">Continue
                                vos achats </a>
                            <!-- <a href="#" class="btn-clear">Vider panier</a> -->
                        </div>
                        <!-- <div class="shopping-cart-coupon" *ngIf="nbrItem > 0">
                            <div class="row">
                                <div class="col-md-5 col-sm-12">
                                    <form action="#" method="POST" class="coupon-form">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Code promo"
                                            #promo
                                            ngModel>
                                            <span class="icon-coupon"></span>
                                        </div>
                                        <button type="submit" class="btn-submit"></button>
                                    </form>
                                </div>
                                <div class="col-md-7 col-sm-12">
                                    <button (click)="checkCoupon(promo)" [disabled]="isCheckCoupon" class="btn-update-cart">Appliquer</button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </section>

    <!-- </main>
    
</div>
<app-footer></app-footer> -->
<!-- <footer>
    <div class="copyright">
        <div class="container-fluid">
            <span>© <a href="#" title="">MarketBey</a> - All Rights Reserved.</span>

        </div>
    </div>
</footer> -->
