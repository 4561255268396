import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CmdsService } from 'src/app/services/cmds/cmds.service';

@Component({
  selector: 'app-commandes',
  templateUrl: './commandes.component.html',
  styleUrls: ['./commandes.component.css']
})
export class CommandesComponent implements OnInit {

  listCmdsUser: any = {};

  constructor(private router: Router,
    private cmdsService: CmdsService) { }

  ngOnInit(): void {

   this.listCmds()
  }


  async listCmds() {
    await this.cmdsService.cmdsUser().then((res: any) => {
      if (res) {
        if (res) {
          this.listCmdsUser = res
          //console.log(this.listCmdsUser)
        }
      }
    });
  }

}
