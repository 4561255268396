<div *ngIf="(router.includes('search'));else contenu">
    <app-navbar-search></app-navbar-search>
</div>
<ng-template #contenu>
    <app-navbar></app-navbar>
</ng-template>

<div class="page-content" [class]="(router.includes('search')) ? 'search' : ''">
    <app-menu></app-menu>
    <main>
        <div>
            <router-outlet #o="outlet"></router-outlet>
        </div>
    </main>
</div>

<app-footer *ngIf="router != '/' && !router.includes('param')"></app-footer>