<!-- <app-navbar></app-navbar>
<div class="page-content">
    <app-menu></app-menu>
    <main> -->
        <section class="checkout-page">
            <div class="container-fluid">
                <div class="heading-sub">
                    <ul class="other-link-sub">
                        <li><a href="#" title="Acceuil">Acceuil</a></li>
                        <li>Historique des commandes</li>
                    </ul>
                </div>
              
                <div class="complete-page-cmd text-center">
                    <div class="row">
                        <!-- <div class="col-md-12 col-sm-4 col-xs-4"></div> -->
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h2 class="status text-center">
                                <a class="logo " href="#" title="MarketBay"><img src="assets/images/logo_blue.png"
                                        alt="images" height="80px" class="img-reponsive"></a>

                                <!-- <span class="iconify" data-icon="ion-checkmark-circled" data-inline="false"></span> -->
                                <br /><br />
                                Historique des commandes
                            </h2>
                        </div>
                        <!-- <div class="col-md-12 col-sm-4 col-xs-4"></div> -->
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <!-- <div class="card"> -->
                                <!-- <div class="card-body"> -->
                                    <!-- <div class="clearfix mb-3"></div> -->
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th></th>
                                                    <th>Référence</th>
                                                    <th>Date Création</th>
                                                    <th>Etat de la commande</th>
                                                    <!-- <th>Livraison</th> -->
                                                    <th>Total Commande</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let cmd of listCmdsUser">
                                                    <td></td>
                                                    <td class="text-left">{{cmd.ref_cmd}}</td>
                                                    <td class="text-left">{{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}</td>
                                                    <td class="text-left">{{cmd.etat_cmd}} </td>
                                                    <!-- <td>{{cmd.bulk_session_datas_cmd.mode_livraison}}</td> -->
                                                    <td class="text-left">
                                                        {{cmd.total_ca_cmd +
                                                        cmd.bulk_session_datas_cmd.frais_livraison}} TND
                                                    </td>
                                                    <!-- <td>{{cmd.nbr_prdt_cmd}}</td> -->

                                                    <td class="text-left">

                                                        <button class="btn btn-primary" [routerLink]="['/details-commande']"
                                                        [queryParams]="{ cmd: cmd._id }" type="button"
                                                            data-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false">Détails

                                                        </button>

                                                    </td>
                                                    <td></td>


                                                </tr>

                                            </tbody>



                                        </table>
                                    </div>

                                <!-- </div> -->
                            <!-- </div> -->


                        </div>
                    </div>





                </div>



            </div>
        </section>
    <!-- </main>
</div>
<app-footer></app-footer> -->
<!-- <footer>
    <div class="copyright">
        <div class="container-fluid">
            <span>© <a href="#" title="">MarketBey</a> - All Rights Reserved.</span>

        </div>
    </div>
</footer> -->