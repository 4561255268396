import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartService } from 'src/app/services/cart/cart.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit {

  nbrItem: number;
  allItems: any = []
  basePathImage: any = "https://assets.marketbey.tn/produits/"

  productsList: any = [];
  keyword: string = ""
  searchText = '';
  filterdName = [];
  
  isConnected = false
  user: any = null

  // heroes = [
    // { id: 11, name: 'pantoufle', country: 'India' },
    // { id: 12, name: 'Narco', country: 'USA' },
    // { id: 13, name: 'Bombasto', country: 'UK' },
    // { id: 14, name: 'Celeritas', country: 'Canada' },
    // { id: 15, name: 'Magneta', country: 'Russia' },
    // { id: 16, name: 'RubberMan', country: 'China' },
    // { id: 17, name: 'Dynama', country: 'Germany' },
    // { id: 18, name: 'Dr IQ', country: 'Hong Kong' },
    // { id: 19, name: 'Magma', country: 'South Africa' },
    // { id: 20, name: 'Tornado', country: 'Sri Lanka' }
  // ];

  constructor(private route: ActivatedRoute,
    private prdServices: ProductsService,
    private cartService: CartService,
    private usersService: UsersService) { }

  async ngOnInit() {
    window.scrollTo(0, 0);
    
    this.nbrItem = await this.cartService.getNbrItems()
    await this.getInfosUser()
    if (this.user != null) {
      this.isConnected = true
    }

    this.allItems = await this.cartService.getAllItem()

    this.route.queryParams.subscribe((params) => {
      this.keyword = params.keyword;
      this.searchProducts(this.keyword)
    });
  }

  async getInfosUser() {
    await this.usersService.getUserInfos().then((res: any) => {
      if (res) {
        this.user = res;
      }
    });
  }

  async filterNames(newValue) {
    this.searchText = newValue;

    await this.prdServices.searchNameProduct(this.searchText).then((res: any) => {
      if (res) {
        this.filterdName = res;
      }
      // console.log(res);
    });

    // this.filterdName = this.heroes.filter(
    //   item => item.name.toLowerCase().includes(this.searchText.toLowerCase())
    // );
    //console.log(this.filterdName);
  }

  async searchProducts(keyword) {
    await this.prdServices.searchProduct(keyword).then((res: any) => {
      if (res) {
        this.productsList = res;
      }
      // console.log(res);
    });
  }

  searchProd(keyword) {
    this.searchText = keyword
    this.filterdName = []
    this.searchProducts(this.searchText)
  }

}
