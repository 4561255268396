import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';


@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Souhaitez-vous choisir ce point de collecte ?</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
      
    </div>
    <div class="modal-body" style="display: flex; justify-content: center;">
   
    <button type="button" class="btn btn-success" >Oui</button>
    <button type="button" class="btn btn-outline-danger" >Non</button>
  
    </div>
   
  `
})
export class NgbdModalContent {
  constructor(public activeModal: NgbActiveModal,
  ) { }
}

@Component({
  selector: 'app-point-collect',
  templateUrl: './point-collect.component.html',
  styleUrls: ['./point-collect.component.css']
})

export class PointCollectComponent implements OnInit {

  constructor( private modalService: NgbModal,
    private httpClient : HttpClient) { }

  latitude: number;
  longitude: number;
  zoom:number;
  contentString =
  '<div id="content">' +
  '<div id="siteNotice">' +
  "</div>" +
  '<h1 id="firstHeading" class="firstHeading">Uluru</h1>' +
  '<div id="bodyContent">' +
  "<p><b>Uluru</b>, also referred to as <b>Ayers Rock</b>, is a large " +
  "sandstone rock formation in the southern part of the " +
  "Northern Territory, central Australia. It lies 335&#160;km (208&#160;mi) " +
  "south west of the nearest large town, Alice Springs; 450&#160;km " +
  "(280&#160;mi) by road. Kata Tjuta and Uluru are the two major " +
  "features of the Uluru - Kata Tjuta National Park. Uluru is " +
  "sacred to the Pitjantjatjara and Yankunytjatjara, the " +
  "Aboriginal people of the area. It has many springs, waterholes, " +
  "rock caves and ancient paintings. Uluru is listed as a World " +
  "Heritage Site.</p>" +
  '<p>Attribution: Uluru, <a href="https://en.wikipedia.org/w/index.php?title=Uluru&oldid=297882194">' +
  "https://en.wikipedia.org/w/index.php?title=Uluru</a> " +
  "(last visited June 22, 2009).</p>" +
  "</div>" +
  "</div>";

  coords = [{
    latitude1 : 36.8419567,
  longitude1 : 	10.1722384 },
   {
    latitude2 : 36.8489303,
    longitude2 : 	10.1760385},
    {
      latitude3 : 36.8665367,
      longitude3 : 	10.1647233}
 ];

  ngOnInit(): void {
    this.setCurrentLocation();
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }

  open() {
        const modalRef = this.modalService.open(NgbdModalContent);
       modalRef.componentInstance.name = 'Fares';
  
  }
   
}
