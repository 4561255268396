import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart/cart.service';
import { CmdsService } from 'src/app/services/cmds/cmds.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  nbrItem: number;

  allItems: any = []
  basePathImage: any = "https://assets.marketbey.tn/produits/"

  totalPrice: number = 0
  choiceLivraison: any = {}
  priceLivraison: number = 0
  msgErrorCart: string = null;


  constructor(private cartService: CartService,
    private router: Router,
    private cmdsService: CmdsService) { }

  async ngOnInit() {
    window.scrollTo(0, 0);

    this.allItems = await this.cartService.getAllItem()
    this.nbrItem = await this.cartService.getNbrItems()
    await this.totalPriceFromCart()
    await this.listLivraison()
  }

  checkPriceLivraison(event: any) {
    this.priceLivraison = Number(event.target.value)
  }

  async listLivraison() {
    await this.cmdsService.getPrixLivraison().then((res: any) => {
      if (res) {
        if (res) {
          this.choiceLivraison = res
          this.priceLivraison = this.choiceLivraison.livraisonStandard
        }
      }
    });
  }

  async minuxFromCart(product) {
    await this.cartService.minuxFromCart(product)
    this.nbrItem = await this.cartService.getNbrItems()
    await this.totalPriceFromCart()
  }

  totalPriceFromCart() {
    let total = 0
    this.allItems.forEach(element => {
      total += element.price * element.quantite
    });
    this.totalPrice = total
  }

  async addToCart(product) {
    await this.cartService.addToCart(product)
    this.nbrItem = await this.cartService.getNbrItems()
    await this.totalPriceFromCart()
    
  }

  async removeFromCart(product) {
    await this.cartService.removeFromCart(product)
    this.nbrItem = await this.cartService.getNbrItems()
    await this.totalPriceFromCart()
  }

  async checkout() {
    this.msgErrorCart = null;
    let allItems = await this.cartService.getAllItem()
    if (allItems.length > 0 && 
      ((!this.cartService.isOneProductFree() && this.totalPrice > 0) 
      || (this.cartService.isOneProductFree() && this.totalPrice >= 20))) {
      this.router.navigate(['/checkout'])
    } else {
      if (allItems.length == 0) {
        this.msgErrorCart = "Votre panier est vide"
      } else if (this.totalPrice == 0) {
        this.msgErrorCart = "Votre panier est invalide"
      } else if(this.cartService.isOneProductFree() 
            && this.totalPrice < 20) {
        this.msgErrorCart = "Votre panier est invalide, il faut acheter des autres produits, plus de 20 DT"
      }
      //console.log("Cart is empty")
    }
  }



}
