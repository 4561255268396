import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart/cart.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { UsersService } from 'src/app/services/users/users.service';
import * as $ from 'jquery';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {

  @Input() nbrItem: number = 0
  searchText = '';
  filterdName = [];

  isConnected = false
  user: any = null

  // heroes = [
  //   { id: 11, name: 'pantoufle', country: 'India' },
  //   { id: 12, name: 'Narco', country: 'USA' },
  //   { id: 13, name: 'Bombasto', country: 'UK' },
  //   { id: 14, name: 'Celeritas', country: 'Canada' },
  //   { id: 15, name: 'Magneta', country: 'Russia' },
  //   { id: 16, name: 'RubberMan', country: 'China' },
  //   { id: 17, name: 'Dynama', country: 'Germany' },
  //   { id: 18, name: 'Dr IQ', country: 'Hong Kong' },
  //   { id: 19, name: 'Magma', country: 'South Africa' },
  //   { id: 20, name: 'Tornado', country: 'Sri Lanka' }
  // ];

  subscription: Subscription;

  constructor(private router: Router,
    private cartService: CartService,
    private prdServices: ProductsService,
    private usersService: UsersService) {
      
    this.isConnected = this.usersService.isConnected()
    }

  async ngOnInit() {
    this.nbrItem = await this.cartService.getNbrItems()
    await this.getInfosUser()

    const source = interval(1000);
    this.subscription = source.subscribe(async (val) => {
      this.nbrItem = await this.cartService.getNbrItems()
    });
  }
  
  openMenu() {
    $("#sm-menu").toggleClass("open");
  }

  async deconnexion() {
    await this.usersService.deconnexion()
    // this.router.navigate(['/home'])
    window.location.reload()
    this.router.navigate(['/'])
  }

  async getInfosUser() {
    await this.usersService.getUserInfos().then((res: any) => {
      //console.log("getUserInfos");
      //console.log(res);
      if (res) {
        this.user = res;
      }
    });
  }

  async filterNames(newValue) {
    this.searchText = newValue;
    await this.prdServices.searchNameProduct(this.searchText).then((res: any) => {
      if (res) {
        //console.log("res search prodssss")
        //console.log(res)
        this.filterdName = res;
      }
      // console.log(res);
    });

    // this.filterdName = this.heroes.filter(
    //   item => item.name.toLowerCase().includes(this.searchText.toLowerCase())
    // );
    //console.log(this.filterdName);
  }

  searchProduct(keyword) {
    //console.log("navbarrrrrrrrrrrrrrrrrr");
    //console.log(keyword);
    this.searchText = keyword;
    this.router.navigate(['/search'], { queryParams: { keyword: this.searchText } });
    // (click)="searchProduct(keyword)"
  }

}
