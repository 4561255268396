<!-- <app-navbar nbrItem="{{ nbrItem }}"></app-navbar>
<div class="page-content"> -->

    <!-- <div class="changeable-menu">
        <div *ngFor="let cat of catsList" class="changeable-sous-menu" >
            <a [routerLink]="['/home']" [queryParams]="{ cat: cat.id }"></a>
            {{cat.label}}
        </div>

        -- <a href="./" title="Home"><i class="fa fa-home"></i>Home</a>
        <a href="./shop-list.html" title="Accessoires, Mentres Et Bijoux ">
            Accessoires, Mentres Et Bijoux
        </a>
        <a href="./shop-list.html" title="Maison El Deco ">Maison El Deco </a>
        <a href="./shop-list.html" title="Telephonie ">Telephonie </a>
        <a href="./shop-list.html" title="Luneffes De Soleil ">Luneffes De Soleil </a>
        <a href="./shop-list.html" title="Artsanat ">Artsanat </a>
        <a href="./shop-list.html" title="Recepteur Et Cameras ">Recepteur Et Cameras </a> --
    </div> -->
    <!-- <app-menu></app-menu> -->
    <!-- <main> -->
        <section class="shop-single-page">
            <div class="container-fluid">
                <div class="heading-sub">
                    <ul class="other-link-sub">
                        <li *ngIf="cat.label_cat=='Acceuil'"><a [routerLink]="[ '/']" title="Acceuil">Acceuil</a>
                        </li>
                        <li *ngIf="cat.label_cat!='Acceuil'" [routerLink]="['/']" [queryParams]="{ cat: cat._id }">
                            {{cat?.label_cat}}</li>
                        <li><a class="active">{{product.prd.nom_produit}}</a></li>
                    </ul>

                </div>
                <div class="widget-shop-single">
                    <div class="row align-start">
                        <div class="col-md-5 col-xs-12">
                            <div class="shop-single-item-img">
                                <div class="main-img">
                                    <div class="main-img-item"
                                        style="background-image: url({{photoProduct}}); background-position: center; background-repeat: no-repeat; background-size: contain; ">
                                        <!-- <a href="#"><img src="{{photoProduct}}" alt="images" class="img-responsive"></a> -->
                                    </div>
                                    <!-- <div class="main-img-item">
                                        <a href="#"><img src="https://picsum.photos/id/133/800" alt="images"
                                                class="img-responsive"></a>
                                    </div> -->
                                    
                                </div>
                                <ul class="multiple-img-list">
                                    <li *ngFor="let photo of product?.prd?.galerie_photos_produit">
                                        <div class="product-col" (mouseenter)="hoverPhoto(photo)">
                                            <div class="img-list">
                                                <img src="{{basePathImage}}{{photo}}" alt="images"
                                                    class="img-responsive">
                                            </div>
                                        </div>
                                    </li>
                                    <!-- <li>
                                        <div class="product-col">
                                            <div class="img">
                                                <a href="#"><img src="https://picsum.photos/id/133/800" alt="images"
                                                        class="img-responsive"></a>
                                            </div>
                                        </div>
                                    </li> -->
                                    
                                    
                                   
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-7 col-xs-12">
                            <div class="shop-sing-item-detail">
                                <h3>{{product.prd.nom_produit}}</h3>
                                <!-- <div class="brandname">by <a href="#"><strong>SONY</strong></a></div> -->
                                <!-- <div class="iuoOIr">
                                    <div class="dXAPTv">
                                        <svg
                                            viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg"
                                            style="height: 16px; width: 16px; cursor: auto; margin: 0px 4px 0px 0px;">
                                            <path
                                                d="M3.142 11.886c-.55.28-.91.027-.806-.567l.642-3.617L.26 5.14c-.445-.42-.307-.83.308-.917l3.754-.528L6.002.405c.275-.54.72-.54.996 0l1.679 3.29 3.754.528c.615.087.754.497.307.917l-2.716 2.562.642 3.617c.105.594-.256.847-.806.567L6.5 10.178l-3.358 1.708z"
                                                fill="#2fb7ec"></path>
                                        </svg>
                                        <svg viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg"
                                            style="height: 16px; width: 16px; cursor: auto; margin: 0px 4px 0px 0px;">
                                            <path
                                                d="M3.142 11.886c-.55.28-.91.027-.806-.567l.642-3.617L.26 5.14c-.445-.42-.307-.83.308-.917l3.754-.528L6.002.405c.275-.54.72-.54.996 0l1.679 3.29 3.754.528c.615.087.754.497.307.917l-2.716 2.562.642 3.617c.105.594-.256.847-.806.567L6.5 10.178l-3.358 1.708z"
                                                fill="#2fb7ec"></path>
                                        </svg>
                                        <svg viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg"
                                            style="height: 16px; width: 16px; cursor: auto; margin: 0px 4px 0px 0px;">
                                            <path
                                                d="M3.142 11.886c-.55.28-.91.027-.806-.567l.642-3.617L.26 5.14c-.445-.42-.307-.83.308-.917l3.754-.528L6.002.405c.275-.54.72-.54.996 0l1.679 3.29 3.754.528c.615.087.754.497.307.917l-2.716 2.562.642 3.617c.105.594-.256.847-.806.567L6.5 10.178l-3.358 1.708z"
                                                fill="#2fb7ec"></path>
                                        </svg>
                                        <svg viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg"
                                            style="height: 16px; width: 16px; cursor: auto; margin: 0px 4px 0px 0px;">
                                            <g fill="none" fill-rule="evenodd">
                                                <path
                                                    d="M6.5 10.18L3.142 11.89c-.55.28-.91.027-.806-.567l.642-3.617L.26 5.143c-.445-.42-.307-.83.308-.917l3.754-.528L6.002.407c.275-.539.72-.54.996 0l1.68 3.29 3.753.529c.615.087.754.496.307.917l-2.716 2.562.642 3.617c.105.593-.256.846-.806.567L6.5 10.18z"
                                                    fill="#d4e3eb"></path>
                                                <path
                                                    d="M3.142 11.889c-.55.28-.91.027-.806-.567l.642-3.617L.26 5.143c-.445-.42-.307-.83.308-.917l3.754-.528L6.002.407c.21-.44.49-.404.49-.404L6.5 10.18 3.142 11.89z"
                                                    fill="#2fb7ec"></path>
                                            </g>
                                        </svg>
                                        <svg viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg"
                                            style="height: 16px; width: 16px; cursor: auto; margin: 0px 4px 0px 0px;">
                                            <path
                                                d="M6.5 10.178l-3.358 1.708c-.55.28-.91.027-.806-.567l.642-3.617L.26 5.14c-.445-.42-.307-.83.308-.917l3.754-.528L6.002.405c.275-.54.72-.54.996 0l1.68 3.29 3.753.528c.615.087.754.497.307.917l-2.716 2.562.642 3.617c.105.594-.256.847-.806.567L6.5 10.178z"
                                                fill="#d4e3eb"></path>
                                        </svg>
                                    </div>
                                    <div class="OrTkn">(173 avis)</div>
                                </div> -->
                                <div class="prod-price details-prd">
                                    <span class="price">{{product.prd.prix_produit}} TND</span>
                                    <span class="price old"
                                        *ngIf="product.prd.ancienprix_produit != null && product.prd.ancienprix_produit != ''">
                                        {{product.prd.ancienprix_produit}} TND</span>

                                    <!-- <span class="tax"><img class="check" src="assets/images/check.svg" alt="check"
                                            width="20px" *ngIf="product.prd.tested" /></span> -->
                                </div>
                                <div class="description">
                                    <h2>Description</h2>
                                    <p>{{product.prd.description_produit}}</p>
                                    <!-- <ul>
                                        <li>More than just pitch black, get the best shades of black with Triple
                                            Black
                                            Technology. </li>
                                        <li>HDR 1000 mirrors the high contrast and vividness the way movie makers
                                            intended
                                        </li>
                                        <li>Get richer colors and deeper contrast with UHD Dimming</li>
                                    </ul> -->
                                </div>
                                <div class="billing-details-content">
                                    <div class="form-group">
                                        <div class="row" *ngFor="let variation of product?.prd?.variations">
                                            <div class="col-xs-12">
                                                <strong><b>{{variation.label}}</b></strong>
                                                <select name="CountryType" class="form-control"
                                                    (change)="selectedVariations($event, variation.label)">
                                                    <option [value]="variat" *ngFor="let variat of variation.valeurs">
                                                        {{variat}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                <!-- <div class="qte-epuise" *ngIf="product.prd.stock_reelle <= 0">
                                    <span>Quantité épuisée !</span>
                                </div>
                                <div class="group-button" *ngIf="product.prd.stock_reelle == 0" >
                                    <div class="button-ver2" >
                                     
                                        <button type="button" class="link-ver1 addcart-ver2" disabled><span
                                                class="icon"></span>Ajouter au
                                            panier</button>
                                    </div>
                                </div> -->
                                <div class="group-button">
                                    <div class="button-ver2" >
                                        <!-- <a class="link-ver1 addcart-ver2" (click)="addToCart()"><span class="icon"></span>Ajouter au
                                            panier</a> -->

                                        <button type="button" class="link-ver1 addcart-ver2" (click)="addToCart()"><span
                                                class="icon"></span>Ajouter au
                                            panier</button><br>
                                        <label class="msg-error" *ngIf="msgError!=null">{{msgError}}</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="product-detail-bottom">
                        <ul class="market-tabs">
                            <!-- <li class="active"><a data-toggle="tab" href="#desc">Description</a></li> -->
                            <!-- <li><a data-toggle="tab" href="#special">Specifications</a></li> -->
                            <li><a data-toggle="tab" href="#video">Video</a></li>
                        </ul>
                        <div class="tab-content padding-lr">
                            <!-- <div id="desc" class="tab-pane fade in active">
                                <p>{{product.prd.description_produit}}</p>
                            </div> -->
                            <!-- <div id="special" class="tab-pane fade">
                                <p></p>
                            </div> -->

                            <div id="video" class="tab-pane fade" *ngIf="product.prd.video != null">
                                <iframe width="100%" height="500" [src]="videoProduct|safe: 'resourceUrl'"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>

                                <!-- <mat-video title="Video" [autoplay]="true" [preload]="true" [fullscreen]="true"
                                    [download]="false" color="accent" spinner="spin">
                                    <source matVideoSource src="{{videoProduct}}" type="video/mp4">

                                </mat-video> -->
                            </div>

                        </div>
                    </div>
                </div>
                <!-- <div class="related-product-page">
                    <div class="heading-shop">
                        <h3>UPSELL PRODUCT</h3>
                    </div>
                    <div class="widget-product-related">
                        <div class="owl-carousel owl-theme js-owl-product">
                            <div class="product-item">
                                <div class="prod-item-img bd-style-2">
                                    <img src="https://picsum.photos/id/133/800" alt="images" class="img-responsive">
                                    <div class="button">
                                        <a href="#" class="addcart">ADD TO CART</a>
                                    </div>
                                    <div class="product-tag pink">Sale</div>
                                </div>
                                <div class="product-item-info-related">
                                    <h3><a href="./shop-single.html"> Sony Xperia X Compact - Unlocked Smartphone...
                                        </a>
                                    </h3>
                                    <div class="prod-price">
                                        <span class="price old">$299.6</span>
                                        <span class="price">$199.69</span>
                                    </div>

                                </div>
                            </div>
                            <div class="product-item">
                                <div class="prod-item-img bd-style-2">
                                    <img src="https://picsum.photos/id/133/800" alt="images" class="img-responsive">
                                    <div class="button">
                                        <a href="#" class="addcart">ADD TO CART</a>
                                    </div>
                                </div>
                                <div class="product-item-info-related">
                                    <h3><a href="./shop-single.html"> Sony Xperia X Compact - Unlocked Smartphone...
                                        </a>
                                    </h3>
                                    <div class="prod-price">
                                        <span class="price old">$299.6</span>
                                        <span class="price">$199.69</span>
                                    </div>

                                </div>
                            </div>
                            <div class="product-item">
                                <div class="prod-item-img bd-style-2">
                                    <img src="https://picsum.photos/id/133/800" alt="images" class="img-responsive">
                                    <div class="button">
                                        <a href="#" class="addcart">ADD TO CART</a>
                                    </div>
                                </div>
                                <div class="product-item-info-related">
                                    <h3><a href="./shop-single.html"> Sony Xperia X Compact - Unlocked Smartphone...
                                        </a>
                                    </h3>
                                    <div class="prod-price">
                                        <span class="price old">$299.6</span>
                                        <span class="price">$199.69</span>
                                    </div>

                                </div>
                            </div>
                            <div class="product-item">
                                <div class="prod-item-img bd-style-2">
                                    <img src="https://picsum.photos/id/133/800" alt="images" class="img-responsive">
                                    <div class="button">
                                        <a href="#" class="addcart">ADD TO CART</a>
                                    </div>
                                </div>
                                <div class="product-item-info-related">
                                    <h3><a href="./shop-single.html"> Sony Xperia X Compact - Unlocked Smartphone...
                                        </a>
                                    </h3>
                                    <div class="prod-price">
                                        <span class="price old">$299.6</span>
                                        <span class="price">$199.69</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </section>
    <!-- </main>
</div>
<app-footer></app-footer> -->
<!-- <footer>
    <div class="copyright">
        <div class="container-fluid">
            <span>© <a href="#" title="">MarketBey</a> - All Rights Reserved.</span>

        </div>
    </div>
</footer> -->