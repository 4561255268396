import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { HttpClientModule } from '@angular/common/http';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AvatarModule } from 'ngx-avatar';
import { DetailsProductComponent } from './pages/details-product/details-product.component';
import { RegisterComponent } from './pages/register/register.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MenuComponent } from './components/menu/menu.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SearchComponent } from './pages/search/search.component';
import { FooterComponent } from './components/footer/footer.component';
import { CartComponent } from './pages/cart/cart.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from './guard/auth.guard';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { PassCmdComponent } from './pages/pass-cmd/pass-cmd.component';
import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import { ProfileComponent } from './pages/profile/profile.component';
import { ForgetPassWordComponent } from './pages/forget-pass-word/forget-pass-word.component';
import { PointCollectComponent } from './pages/point-collect/point-collect.component';
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceClientComponent } from './pages/service-client/service-client.component';
import { CommandesComponent } from './pages/commandes/commandes.component';
import { DetailsCmdComponent } from './pages/details-cmd/details-cmd.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SafePipe } from './pipes/safe.pipe';
import { ObjNgForPipe } from './pipes/obj-ng-for.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalComponent } from './components/modal/modal.component';
import { ModelProfilComponent } from './components/model-profil/model-profil.component';
import { AproposNousComponent } from './pages/apropos-nous/apropos-nous.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PaiementComponent } from './pages/paiement/paiement.component';
import { RetourComponent } from './pages/retour/retour.component';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { PoliticConfComponent } from './pages/politic-conf/politic-conf.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    DetailsProductComponent,
    RegisterComponent,
    NavbarComponent,
    MenuComponent,
    SearchComponent,
    FooterComponent,
    CartComponent,
    CheckoutComponent,
    PassCmdComponent,
    ProfileComponent,
    ForgetPassWordComponent,
    PointCollectComponent,
    ServiceClientComponent,
    CommandesComponent,
    DetailsCmdComponent,
    SafePipe,
    ObjNgForPipe,
    ModalComponent,
    ModelProfilComponent,
    AproposNousComponent,
    ContactComponent,
    PaiementComponent,
    RetourComponent,
    BaseLayoutComponent,
    PoliticConfComponent
  ],

  imports: [
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCD32IFxnEfzr0ZSIEUDvsPra97PvUtHto'
    }),
    AppRoutingModule,
    HttpClientModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    Ng2SearchPipeModule,
    AvatarModule,
    FormsModule,
    SlickCarouselModule,
    SocialLoginModule,
    BrowserAnimationsModule,
    MatDialogModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
      AngularFireAnalyticsModule
  ],

  providers: [AuthGuard,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('307780777229533')

          }
        ]
      } as SocialAuthServiceConfig,
    },
    ScreenTrackingService,
    UserTrackingService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
