import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class CmdsService {

  userId: string = "";

  constructor(private http: HttpClient, private UsersService: UsersService) { }

  async getPrixLivraison() {
    return await new Promise((slv) => {
      this.http.post(environment.apiPrixlivraison, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async addCmd(cmd, user, modeLivraison, fraisLivraison, pcollect,
    adresse, ville, codepostal, numtel, coupon?) {
    //console.log("userrrrrrrrrrr : ")
    //console.log(user)
    return await new Promise((slv) => {
      this.http.post(environment.apiAddcmd, {
        "cmd": cmd,
        "user": user,
        "coupon": coupon,
        "mode_livraison": modeLivraison,
        "frais_livraison": fraisLivraison,
        "pcollect": /*modeLivraison == "PCOLLECT" &&
          pcollect != null &&
          pcollect["id"] != null
          ? pcollect["id"]
          : */"",
        "nom_pcollect": /*modeLivraison == "PCOLLECT" &&
          pcollect != null &&
          pcollect["name"] != null
          ? pcollect["name"]
          : */"",
        "adresse": adresse,
        "ville": ville,
        "codepostal": codepostal,
        "numtel": numtel,
      }).subscribe(
        (data: any) => {
          //console.log("add cmd service data")
          //console.log(data)
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async cmdsUser() {
    this.userId = await this.UsersService.getIdUser()
    // console.log("uuuuuuuuuuuuuuuser cmd")
    // console.log(this.userId)

    if (this.userId != null) {
      return await new Promise((slv) => {
        this.http.post(environment.apiCmdsUser + "/" + this.userId, {}).subscribe((data: any) => {
          //console.log("cmds service data")
          //console.log(data)
          if (data && data.response) {
            slv(data.data);
          } else slv(false);
        })
      })
    }

  }

  getOneCMD(cmd) {
    return new Promise((slv) => {
      this.http.post(environment.apiOneCmdByID, { cmd }).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  checkCoupon(coupon, idUser) {
    return new Promise((slv) => {
      this.http.post(environment.apiCheckcoupon, {
        "user": idUser,
        "coupon": coupon,
      }).subscribe(
        (data: any) => {
          // console.log("dataaaaaa : ");
          // console.log(data);
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
}
