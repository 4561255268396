<!-- <app-navbar nbrItem="{{ nbrItem }}"></app-navbar>
<div class="page-content">
    <app-menu></app-menu>
    <main> -->
        <section class="checkout-page">
            <div class="container-fluid">
                <div class="heading-sub">
                    <ul class="other-link-sub">
                        <li><a [routerLink]="[ '/']" title="Acceuil">Acceuil</a></li>
                    </ul>
                </div>
                <ul class="breadcrumb">
                    <li>
                        <a href="#">01. Panier</a>
                    </li>
                    <li>
                        <a href="#">02. Livraison</a>
                    </li>
                    <li>
                        <a class="active" href="#" title="">03. Commande</a>
                    </li>
                </ul>
                <div class="complete-page text-center">
                    <h2 class="status">
                        <span class="fa-5x icon-check"></span>
                        
                        <!-- <span class="iconify" data-icon="ion-checkmark-circled" data-inline="false"></span> -->
                        <br /><br />
                        Merci d'avoir passé une commande sur Market Bey!
                    </h2>
                </div>
                <div>
                    <h3>UN PAS DE PLUS</h3>
                </div>
                <div>
                    <p> Toutes nos félicitations! Votre commande a été créée avec succès. Vous recevrez un e-mail de confirmation ou un SMS. 
                        Notre service client pourrait vous contacter sous peu pour vérifier votre commande.</p>
                        <br />
                </div>
                <div>
                    <h3>SUIVRE VOTRE COMMANDE</h3>
                    <p>
                        Vous pouvez suivre votre commande dans: <br />
                        <a [routerLink]="[ '/commande']" title="commandes">Historique de commandes</a> 
                        <a>--> Détails</a>
                    </p>
                </div>
            </div>
        </section>
    <!-- </main>
</div>
<app-footer></app-footer> -->
<!-- <footer>
    <div class="copyright">
        <div class="container-fluid">
            <span>© <a href="#" title="">MarketBey</a> - All Rights Reserved.</span>

        </div>
    </div>
</footer> -->
