import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UsersService } from '../services/users/users.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private usersService: UsersService,
    private router: Router) {
  }

  canActivate(): boolean {
    if (this.usersService.isConnected()) {
      return true
    } else {
      this.router.navigate(['/login'])
      return false
    }
  }

}
