<!-- <app-navbar></app-navbar>
<div class="page-content">
    <app-menu></app-menu>
    <main> -->
        <section class="checkout-page">
            <div class="container-fluid">
                <div class="heading-sub">
                    <ul class="other-link-sub">
                        <li><a [routerLink]="[ '/']" title="Acceuil">Acceuil</a></li>
                        <li><a [routerLink]="[ '/commande']" title="commande">Historique des commandes</a></li>
                        <li>Détails commande</li>
                    </ul>
                </div>
               
                <div class="complete-page-cmd text-center">
                    <div class="row">
                        <!-- <div class="col-lg-12 col-md-12 col-sm-4 col-xs-4"></div> -->
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h2 class="status text-center">
                                <a class="logo" href="#" title="MarketBay"><img src="assets/images/logo_blue.png"
                                        alt="images" height="80px" class="img-reponsive"></a>

                                <!-- <span class="iconify" data-icon="ion-checkmark-circled" data-inline="false"></span> -->
                                <br /><br />
                                Détails commandes
                            </h2>
                        </div>
                        <!-- <div class="col-md-12 col-sm-4 col-xs-4"></div> -->
                    </div>

                    <div class="row">

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div id="commande">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="invoice-title text-center">
                                            <h2>Commande: {{cmd.ref_cmd}}</h2>

                                        </div>
                                        <hr>

                                        <div class="row">

                                            <!-- <div class="col-md-6">
                                        <qrcode print [width]="150" usesvg="true" [elementType]="'svg'" [qrdata]="cmd._id"
                                          [errorCorrectionLevel]="'M'"></qrcode>
                                      </div> -->
                                            <div class="col-md-6 text-left">
                                                <address>
                                                    <strong>Client:</strong><br>
                                                    {{cmd.facturation.nom}}<br>
                                                    {{cmd.facturation.email}}<br>
                                                    {{cmd.facturation.numtel}}<br>
                                                    {{cmd.facturation.adresse}}<br />
                                                </address>
                                                <address>
                                                    <strong>Date de commande:</strong><br>
                                                    {{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}<br>
                                                    <!-- <div *ngIf="cmd.etat_cmd" class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                            {{statusCmds[cmd.etat_cmd].label}}
                                          </div> -->
                                                </address>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <!-- <div class="section-title">Détails de la commande</div> -->
                                        <div class="table-responsive">
                                            <table class="table table-striped table-hover table-md">
                                                <thead>
                                                    <tr>
                                                        <th data-width="40"></th>
                                                        <th class="text-center">Photo Prdt</th>
                                                        <th>Nom Prdt</th>
                                                        <th>Prix unitaire</th>
                                                        <th>Quantite</th>
                                                        <th>Montant</th>
                                                    </tr>

                                                </thead>

                                                <tr *ngFor="let prd of cmd.basket.prdts, let index = index">
                                                    <td>{{index+1}}</td>
                                                    <td><img alt="image" src="{{baseURL}}/{{prd.image}}" width="100"
                                                            data-toggle="title" title="">
                                                    </td>
                                                    <td class="text-left">{{prd.nom}}
                                                        <!-- <span *ngFor="let variation of prd?.selectedVariation | objNgFor">
                                                            <div>{{variation.key}}: {{variation.value}}</div>
                                                        </span> -->
                                                    </td>

                                                    <td class="text-left">{{prd.prix_unitaire}} TND</td>
                                                    <td class="text-left">{{prd.quantite}}</td>
                                                    <td class="text-left">{{prd.stotal}} TND</td>

                                                </tr>
                                            </table>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-lg-4 text-left">
                                                <div class="section-title">Mode de livraison:</div>
                                                <p class="section-lead">
                                                    {{cmd?.livraison_cmd.moyen_livraison}} STANDARD
                                                    <br />{{cmd.livraison_cmd.pcollect_details.adresse_pcollect}}
                                                </p>
                                            </div>

                                            <div class="col-lg-8 text-right">
                                                <div class="invoice-detail-item">
                                                    <div class="invoice-detail-name">Prix des article(s):
                                                        {{cmd.total_ca_cmd}} TND</div>
                                                    <div class="invoice-detail-name">Frais de livraison:
                                                        {{cmd?.bulk_session_datas_cmd?.frais_livraison}} TND</div>
                                                    <div class="invoice-detail-value">Montant total:
                                                        {{cmd.total_ca_cmd +
                                                        cmd?.bulk_session_datas_cmd?.frais_livraison}} TND</div>

                                                </div>

                                            </div>

                                            
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <!-- </main>
</div>
<app-footer></app-footer> -->
<!-- <footer>
    <div class="copyright">
        <div class="container-fluid">
            <span>© <a href="#" title="">MarketBey</a> - All Rights Reserved.</span>

        </div>
    </div>
</footer> -->