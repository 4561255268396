import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CmdsService } from 'src/app/services/cmds/cmds.service';

@Component({
  selector: 'app-details-cmd',
  templateUrl: './details-cmd.component.html',
  styleUrls: ['./details-cmd.component.css']
})
export class DetailsCmdComponent implements OnInit {

  cmd: any = {
    facturation: {},
    livraison: {},
    basket: {},
    livraison_cmd: { pcollect_details: {} },
  };

  baseURL: String = environment.baseImagesURL;
  cmdID: string = '';

  constructor(private route: ActivatedRoute, private CmdsServices: CmdsService,) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {
      this.cmdID = params.cmd;
      this._fetchCmd();
    });
  }

  _fetchCmd() {
    this.CmdsServices.getOneCMD(this.cmdID).then((d) => {
      if (d) {
        this.cmd = d;
        //console.log("this.cmd")
        //console.log(this.cmd)
      } else {
        alert('Aucune commande valide');
        location.href = '/commandes';
      }
    });
  }


}
