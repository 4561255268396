import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  isConnected = false
  user: any = null

  constructor(private router: Router,
    private usersService: UsersService) { }

  async ngOnInit(): Promise<void> {
    await this.getInfosUser()
    if (this.user != null) {
      this.isConnected = true
    }
  }

  async getInfosUser() {
    await this.usersService.getUserInfos().then((res: any) => {
      if (res) {
        this.user = res;
      }
    });
  }

}
