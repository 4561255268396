import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products/products.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  catsList: any = [];
  isConnected = false
  user: any = null
  
  basePathIcon: any = "https://assets.marketbey.tn/categories/"

  constructor(private prdServices: ProductsService,
    private router: Router,
    private usersService: UsersService) { }

  async ngOnInit() {
    await this.listCats();
    await this.getInfosUser()
    if (this.user != null) {
      this.isConnected = true
    }
  }

  async getInfosUser() {
    await this.usersService.getUserInfos().then((res: any) => {
      if (res) {
        this.user = res;
      }
    });
  }

  async deconnexion() {
    await this.usersService.deconnexion()
    window.location.reload()
    this.router.navigate(['/'])
  }

  async listCats() {
    await this.prdServices.getCats().then((res: any) => {
      //console.log(res)
      if (res) {
        this.catsList = res;
      }
    });
  }

  onChangeCat() {
    //console.log("aaaaaaaaaaaaaaaaaazzz")
    window.scrollTo(0, 0);
    // console.log("category")
    // console.log(category)
    // this.router.navigate(['/home', { cat: category }]);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }

}
