import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';


@Component({
  selector: 'app-service-client',
  templateUrl: './service-client.component.html',
  styleUrls: ['./service-client.component.css']
})
export class ServiceClientComponent implements OnInit {

  constructor(private UsersService: UsersService, private router: Router) { }

  svCl: {}
  async ngOnInit() {
    window.scrollTo(0, 0);

    this.getsServiceCl()
  }

  async getsServiceCl() {
    await this.UsersService.serviceCl().then((res: any) => {
      //console.log("res service")
      //console.log(res)
      if (res) {
        this.svCl = res
      }
    })
  }

}
