import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-model-profil',
  templateUrl: './model-profil.component.html',
  styleUrls: ['./model-profil.component.css']
})
export class ModelProfilComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
