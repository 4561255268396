<section class="checkout-page">
    <div class="container-fluid">
        <div class="heading-sub">
            <ul class="other-link-sub">
                <li><a [routerLink]="[ '/']" title="Acceuil">Acceuil</a></li>
                <li>Politique de retour</li>
            </ul>
        </div>

        <div class="complete-page text-center">
            <h2 class="status">
                <a class="logo" [routerLink]="[ '/']" title="MarketBey"><img
                        src="assets/images/logo_blue.png" alt="images" height="80px" class="img-reponsive"></a>

                <!-- <span class="iconify" data-icon="ion-checkmark-circled" data-inline="false"></span> -->
                <br /><br />
                Politique de Confidentialité Market Bey
            </h2>
        </div>


        <div class="desc-mb">
            <p>Chez Market Bey (« nous », « notre, nos »), nous reconnaissons l’importance de la vie privée et la confidentialité des données personnelles.<br><br>
                Market bey  est une application destinée aux consommateurs (« B2C ») qui interconnecte et facilite leurs achats.<br><br>
                La présente Politique de Confidentialité définit la façon dont nous collectons, traitons et divulgons les données dans le cadre du fonctionnement de la Plate-forme, y compris des données personnelles vous concernant et concernant les utilisateurs, visiteurs et représentants de la Plate-forme. La présente Politique de Confidentialité s’applique également à l’utilisation de la Plate-forme sur votre appareil mobile.                
            </p>
            <br />
        </div>



        <div>
            <span><b>A. COLLECTE DE DONNÉES</b></span>
            <p>
                Les données personnelles que nous collectons entrent, de manière générale, dans les catégories suivantes :
            </p>
            <span><b>Données Que Vous Nous Communiquez :</b></span>
            <p>
                Si vous êtes Acheteur, nous vous demanderons de communiquer vos :
·        coordonnées, y compris : nom ; adresse ; numéro de téléphone ; adresse email 
       données relatives à vos achats, y compris : les produits que vous achetez et en quelle quantité ;
·        données sur des réclamations et/ou au soutien de réclamations formulées en rapport avec la Plate-forme ou avec des biens et/ou services achetés via la Plate-forme ;

            </p>
            <span><b>B. TRAITEMENT DES DONNÉES PERSONNELLES</b></span>
            <p>
                Nous collectons et traitons vos données personnelles pour les finalités suivantes :
·     vérification des inscriptions de comptes sur la Plate-forme, y compris des vérifications en matière de fraude, de sécurité, de connaissance du client  et de blanchiment de capitaux afin d’approuver vos comptes Vendeur ;
·     vérification de votre éligibilité comme blogueur ou influenceur de la Plate-forme ;
·      création / administration des comptes Acheteur  y compris pour fournir des identifiants de connexion et créer des comptes de paiement / transaction ;
·       fourniture du service clientèle, pour répondre à vos questions et commentaires et gérer les réclamations et/ou litiges ;
 .  vérification de votre identité et des achats en rapport avec la garantie des produits ou d’autres réclamations après-vente ;
 
·       évaluation et suivi des risques liés à la sécurité du compte et des transactions des Acheteurs et Vendeurs, en détectant et prévenant la fraude, le blanchiment de capitaux et d’autres incidents de sécurité ;

            
            </p>
            <br/>
            <span><b>C. DIVULGATION OU PARTAGE DE DONNÉES PERSONNELLES</b></span>
            <p>
                Nous divulguons/partageons des données personnelles avec les catégories de destinataires suivants : 

                D’autres Acheteurs et/ou Vendeurs de la Plate-forme, si cela s'avère nécessaire pour faciliter les achats et communications réalisés entre vous.
                Si vous êtes Acheteur, des Vendeurs auprès desquels vous achetez contrôlent également certaines données personnelles vous concernant en lien avec les achats et communications que vous réalisez avec eux sur la Plate-forme. 
                
               Veuillez consulter les Politiques de confidentialité des Vendeurs pour savoir comment ceux-ci traitent vos données.
                Nos partenaires commerciaux afin qu’ils puissent, par exemple, offrir des rabais ou des offres susceptibles d’intéresser les Acheteurs ou Acheteurs potentiels) lorsque vous avez consenti à recevoir ces informations de leur part, si la législation applicable à la protection des données le requiert 
                Des partenaires marketing et publicitaires,  tels que Google, Twitter, Facebook, Instagram, afin d’adapter le contenu que vous voyez lorsque vous visitez notre Plate-forme à des fins d’analyse et/ou à des fins publicitaires. Ces partenaires peuvent associer des données collectées sur notre Plate-forme avec des données de leurs propres plates-formes et des données qu’ils collectent d’autres sites web ou auprès d’autres sources, afin de produire une publicité ciblée. 
               Des partenaires logistiques fournissant des services de livraison aux Acheteurs et Vendeurs, y compris le retour et l’échange de produits, ainsi que des services d’entreposage pour les Vendeurs
            </p>
            <span><b>D. Conservation :</b></span>
            <p>
                ​​Nous conserverons vos données personnelles aussi longtemps que nous avons légitimement besoin de le faire, par exemple pour vous 
                fournir des services ou des produits, ou bien si les lois en vigueur l’exigent ou l’autorisent. Lorsque nous n’avons aucun besoin commercial légitime de traiter vos données personnelles, nous les supprimerons ou les rendrons anonymes ou, si ce n’est pas possible (par exemple, parce que vos données personnelles ont été conservées dans des archives de sauvegarde), nous les conserverons en toute sécurité et les isolent de tout traitement ultérieur jusqu’à ce qu’il soit possible de les supprimer. La durée de conservation de vos données dépendra également de la manière dont vous utilisez notre site web (par exemple, si vous êtes connecté en tant que membre ou si vous visitez notre site web comme invité) et à quelles finalités sont destinées les données que vous nous communiquez. Par exemple, si vous êtes membre enregistré, certaines données seront conservées pour une certaine durée après la désactivation ou la suppression de votre compte, afin de nous conformer à la législation applicable [(par exemple si nous recevons une ordonnance judiciaire au sujet de votre compte, nous conserverons les données relatives à votre compte plus longtemps que le délai habituel de conservation en cas de suppression de compte)] ou en cas de différends. Dans d’autres cas, la durée de conservation pourra être déterminée en fonction de votre activité sur la Plate-forme ou pour nous soumettre à une durée spécifique d’arbitrage / de règlement de différends.
                
            
            </p>

            <br>
            <span><b>E. DROITS CONCERNANT LES DONNÉES PERSONNELLES
            </b></span>
            <p>
                Au regard du droit applicable, vous pouvez disposer de droits d’accès aux données personnelles que nous détenons.
            </p>

            <br>
            <span><b>F. MESURES DE SÉCURITÉ
            </b></span>
            <p>
                Nous mettons en œuvre des mesures techniques et organisationnelles appropriées afin de prévenir tout accès non autorisé à l’application, de maintenir l’exactitude des données et de garantir l’utilisation correcte des informations que nous détenons. Si nous découvrons une violation des données, nous la notifierons auprès de vous et des autorités réglementaires,  conformément aux délais et à la portée prévus par la loi sur la protection des données en vigueur.<br><br>
Les utilisateurs du Site enregistrés peuvent accéder et modifier certaines de leurs informations depuis leur compte, lequel est protégé par un mot de passe. Nous vous recommandons de ne communiquer votre mot de passe à personne. Nos employés ne vous demanderont jamais de leur communiquer votre mot de passe. Si vous partagez votre ordinateur avec d’autres personnes, nous vous conseillons de ne pas enregistrer vos identifiants de connexion (par exemple, votre nom d’utilisateur et votre mot de passe) sur cet ordinateur commun. Veillez à bien vous déconnecter de votre compte et à fermer la fenêtre de votre navigateur lorsque vous aurez terminé votre session.<br><br>
Aucune transmission de données par Internet ou tout réseau sans fil ne peut être garantie comme parfaitement sécurisée. Par conséquent, même si nous utilisons des mesures techniques et organisationnelles appropriées pour protéger les informations que nous détenons pour vous, nous ne pouvons garantir la sécurité des informations que vous nous transmettez sur Internet.

            </p>

        </div>
    </div>
</section>