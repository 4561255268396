<div class="AuthPageContainer">
    <div class="BackGroundContainer">
  
    </div>
    <div class="AuthInfoContainer">
      <div class="AuthInfoContent">
        <div class="logoContainer">
        </div>
        <div class="AuthenticationPage dBSzDB">Réinitialiser votre mot de passe</div>
        <div class="AuthenticationPage cQHGC">
            Entrez votre adresse email et nous vous enverrons un lien de réinitialisation de votre mot de passe.</div>
        <div class="authInputs" id="login">
          <div>
            <form action="" class="sub-form" method="post" onsubmit="return false;"
              (onsubmit)="forgetPassWord(email);">
              <input type="email" class="form-control" placeholder="Adresse email" name="email" id="email" #email
                required="required" ngModel />
              
              <button type="submit" class="authButton" (click)="forgetPassWord(email)">Envoyer le lien</button>
              
            </form>
          </div>
      </div>
    </div>
  </div>
  