import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import { tap } from 'rxjs/operators';
import { error } from '@angular/compiler/src/util';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Injectable({
  providedIn: 'root'
})

export class UsersService {

  idUser: String = "";
  constructor(private httpClient: HttpClient,
    private analyticsService: GoogleAnalyticsService) { }

  getIdUser() {

    var token = localStorage.getItem('token')
    //console.log("tokennnnnnnnn : ");

    if (token && token != null) {
      var decoded = jwt_decode(token);
      //console.log("idddddddddddddddddduser")
      //console.log(decoded["id"])
      return decoded["id"]
    } else return null
  }

  getUserInfos() {
    var token = localStorage.getItem('token')


    //console.log("tokennnnnnnnn : ");
    //console.log(token);

    return new Promise((solve) => {
      if (token && token != null) {
        var decoded = jwt_decode(token);

        this.httpClient.post(environment.apiInfosUser, { token: token, userid: decoded["id"] }).subscribe((res: any) => {
          //console.log("hajerrrrrrrrrrrrrrrrrrrrrr get info user");
          // console.log(res);
          if (res && res.response && res.data) {
            solve(res.data);
          } else
            solve(true);
        },
          (error) => {
            // console.log("Cmd error")
            // console.log(error)
            solve(false);
          })
      } else {
        solve(false);
      }
    });

    // let user = localStorage.getItem('userdetails');
    // if (user && JSON.parse(user))
    //   return JSON.parse(user);

    // return null;
  }

  isConnected() {
    var token = localStorage.getItem('token')
    if (token)
      return true;

    return false
  }

  saveUserCreds(usercreds) {
    // localStorage.setItem('userdetails', JSON.stringify(usercreds));

    localStorage.setItem('token', JSON.stringify(usercreds.token));
  }

  login(creds) {
    //console.log("creds");
    //console.log(creds);
    return new Promise((solve) => {
      this.httpClient.post(environment.apiUsersAuth, creds).subscribe((res: any) => {
        //console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        //console.log(res.data);
        if (res) {
          if (res.response && res.data) {
            this.saveUserCreds(res.data);
            this.analyticsService.setUserProperties(res.data.id, res.data.nom+""+res.data.prenom, res.data.email)
            solve(true);
          } else {
            solve(res);
          }
        }
      })
    });
  }

  register(creds, source?) {
    //console.log("source");
    //console.log(source);
    if (source == "fb") creds["source"] = "fb";
    //console.log("creds");
    //console.log(creds);
    return new Promise((solve) => {
      this.httpClient.post((source != null && source == "fb") ? environment.apiUsersSocialAuth : environment.apiUsersRegister, creds).subscribe((res: any) => {
        //console.log("FN register service");
        //console.log(res);
        if (res) {
          if (res.response && res.data) {
            this.saveUserCreds(res.data);
            solve(true);
          } else {
            solve(res);
          }
        }
      })
    });
  }

  updateInfosUser(creds) {
    let user = {
      nom: creds.nom_user,
      prenom: creds.prenom_user,
      numtel: creds.tel_user,
      email: creds.email_user,
      adresse: creds.last_shipping_address.adresse,
      ville: creds.last_shipping_address.ville,
      code: creds.last_shipping_address.code,
      userid: creds._id
    }
    //console.log("user update infos")
    //console.log(user)
    return new Promise((solve) => {
      this.httpClient.post(environment.apiUpdateUser, user).subscribe((res: any) => {
        //console.log("updateeeeeeeee : ");
        //console.log(res);
        if (res && res.response) {
          solve(true);
        } else
          solve(false);
      }, (error) => {
        // console.log("Cmd error")
        // console.log(error)
        solve(false);
      })
    });
  }

  forgetPw(email) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiForgetPw, email).subscribe((res: any) => {
        //console.log("res forget password")
        //console.log(res)
        if (res) {
          solve(res.response);
        }
      })

    });

  }

  serviceCl() {
    return new Promise((slv) => {
      this.httpClient.get(environment.apiServiceCl).subscribe((data: any) => {
        if (data && data.response && data.data) {
          slv(data.data);
        } else slv(false);
      },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        })
    });
  }

  deconnexion() {
    localStorage.removeItem('token');
  }

}
