
<!-- <div class="page-content">
    <app-menu></app-menu>
    <main> -->
        <section class="checkout-page">
            <div class="container-fluid">
                <div class="heading-sub">
                    <ul class="other-link-sub">
                        <li><a [routerLink]="[ '/']" title="Acceuil">Acceuil</a></li>
                        <li>Qui sommes nous</li>
                    </ul>
                </div>
                <!-- <ul class="breadcrumb">
                    <li>
                        <a href="checkout-1.html">01. Panier</a>
                    </li>
                    <li>
                        <a href="checkout-2.html">02. Livraison</a>
                    </li>
                    <li>
                        <a class="active" href="checkout-3.html" title="">03. Commande</a>
                    </li>
                </ul> -->
                <div class="complete-page text-center">
                    <h2 class="status">
                        <a class="logo" [routerLink]="[ '/']" title="MarketBay"><img
                                src="assets/images/logo_blue.png" alt="images" height="80px" class="img-reponsive"></a>

                        <!-- <span class="iconify" data-icon="ion-checkmark-circled" data-inline="false"></span> -->
                        <br /><br />
                        Qui sommes nous ?
                    </h2>
                </div>


                <div class="desc-mb">
                    <p> MarketBey est une Marketplace créée en Juin 2020 dont l'objectif et la vision sont d’être le
                        moins cher sur
                        le marché tunisien en proposant des produits d'accessoires de toutes catégories.
                        ( Accessoires et Bijoux/ high tech/ décoration/ Cuisine/ Cosmétique/ Montres et lunettes…)<br />
                        En cliquant sur chaque mots entre eux, il vous amène vers les produits existants dans la
                        catégorie. </p>
                    <br />
                </div>

                <div>
                    <ul>
                        <li>1) Livraison sur toute la Tunisie.</li>
                        <li>2) Nos délais de livraison sont les plus rapides : 24h pour le grand Tunis et 48h à 72h pour
                            les autres régions hors Tunis</li>
                    </ul>
                    <br/>
                </div>

                <div>
                    <span><b>Catégorie high tech:</b></span>
                    <p>
                        Bénéficier de nos offres d’accessoires high-tech sur toutes la Tunisie.
                        Commandez en ligne et profitez de nos prix les moins chers sur le marché tunisien.
                    </p>
                    <span><b>Catégorie décoration:</b></span>
                    <p>
                        Envie de rendre votre maison un paradis, MarketBey votre site de vente des accessoires en ligne,
                        vous offre un choix énormes des produits de décoration de maison.
                        Commandez en ligne et profitez de nos prix les moins chers sur toute la Tunisie.
                    </p>
                    <span><b>Catégorie Lunettes:</b></span>
                    <p>
                        Soyez Chics, beaux et belles en portant les lunettes de MarketBey.
                        Bénéficier de nos prix imbattables sur toute la tunisie en passant votre commande en ligne via
                        notre site.
                        Une Variétés de produits homme et femme sont mis à votre Disposition.
                    </p>
                    <span><b>Accessoires et bijoux:</b></span>
                    <p>
                        Partout sur la Tunisie; passez votre commande en ligne via notre site MarketBey.
                        Soyez la plus belle des femmes et profitez d’un large choix sur les accessoires et bijoux avec
                        le prix le moins cher sur le marché Tunisien.
                    </p>
                    <span><b>Habillement: </b></span>
                    <p>
                        Envie d'être élégante et chic avec le prix le moins cher que possible?
                        Passez votre commande via notre site de vente en ligne et obtenez vos articles préférés en un
                        seul clic sur toute la Tunisie.
                    </p>
                    <span><b>fêtes et anniversaires:</b></span>
                    <p>
                        Profitez en un seul clic ,sur toute la Tunisie, de nos articles conçus spécialement pour vos
                        fêtes et anniversaires.
                    </p>
                    <span><b>jouets:</b></span>
                    <p>
                        Gâter vos enfants avec les jouets de Votre site MarketBey.
                        Passez votre commande en un seul clic et profitez de nos prix imbattables sur le marché
                        Tunisien.
                    </p>

                    <span><b>Cuisine:</b></span>
                    <p>
                        A vous les femmes Tunisiennes,
                        Profitez des articles de cuisine de votre site de vente en ligne MarketBey avec le prix le moins
                        chers sur toutes La tunisie.
                    </p>
                </div>
            </div>
        </section>
    <!-- </main>
</div> -->
<!-- <app-footer></app-footer> -->
<!-- <footer>
    <div class="copyright">
        <div class="container-fluid">
            <span>© <a href="#" title="">MarketBey</a> - All Rights Reserved.</span>

        </div>
    </div>
</footer> -->